import { Outlet } from "react-router-dom";
import PageLayoutWithHeader from "../../../components/layouts/PageLayoutWithHeader";
import { useLeadDetails } from "../../../providers/admin/LeadDetailsProvider";

export const LeadLayout = () => {
  const { lead } = useLeadDetails();

  return (
    <PageLayoutWithHeader
      title={lead ? `${lead.firstName} ${lead.lastName}` : ""}
      subtitle={
        lead ? `${lead.email ?? "---"} • ${lead.phoneNumber ?? "---"}` : ""
      }
    >
      <Outlet />
    </PageLayoutWithHeader>
  );
};
