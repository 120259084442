import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../../../providers/admin/AuthProvider";
import { motion } from "framer-motion";
import { LogOut } from "lucide-react";

const AdminLayout: React.FC = () => {
  const { logout } = useContext(AuthContext);
  return (
    <div className="h-full flex flex-col">
      <nav className="border-b border-gray-200 bg-gray-900 flex-shrink-0">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between">
            <div className="flex"></div>
            <div className="flex items-center">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => logout()}
                className="bg-gray-700 hover:bg-gray-600 text-gray-200 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 flex items-center transition-colors duration-200"
              >
                <LogOut size={20} className="mr-2" />
                <span className="hidden sm:inline">Logout</span>
              </motion.button>
            </div>
          </div>
        </div>
      </nav>
      <div className="flex-grow overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
