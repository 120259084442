import React, { createContext, useContext, useEffect, useState } from "react";
import { Prompt } from "../../domain/src/leads/entities/prompt";
import { useDi } from "../../DiContext";

interface PromptsContextType {
  prompts: Prompt[];
  loading: boolean;
  error: Error | null;
}

const PromptsContext = createContext<PromptsContextType>({
  prompts: [],
  loading: false,
  error: null,
});

export const usePrompts = () => useContext(PromptsContext);

interface PromptsProviderProps {
  children: React.ReactNode;
}

export const PromptsProvider: React.FC<PromptsProviderProps> = ({
  children,
}) => {
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { promptsRepository } = useDi();

  useEffect(() => {
    const fetchAllPrompts = async () => {
      setLoading(true);
      try {
        let currentPage = 1;
        const perPage = 100;
        const maxPages = 5;
        let allPrompts: Prompt[] = [];
        let hasMorePages = true;

        while (hasMorePages && currentPage <= maxPages) {
          const response = await promptsRepository.getPrompts(
            currentPage,
            perPage
          );
          allPrompts = [...allPrompts, ...response.results];

          // Check if there's a next page using the next property
          hasMorePages = response.next !== null;
          currentPage++;
        }

        setPrompts(allPrompts);
        setError(null);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Failed to fetch prompts")
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAllPrompts();
  }, [promptsRepository]);

  return (
    <PromptsContext.Provider value={{ prompts, loading, error }}>
      {children}
    </PromptsContext.Provider>
  );
};
