import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { ValueMetric } from "../../../domain/src/leads/entities/valueMetric";
import { useEffect, useState } from "react";

interface ValueMetricCardProps {
  metric: ValueMetric;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const ValueMetricCard: React.FC<ValueMetricCardProps> = ({ metric }) => {
  const [isIncrease, setIsIncrease] = useState(false);
  const [isDecrease, setIsDecrease] = useState(false);
  const [formattedValue, setFormattedValue] = useState("");
  const [formattedPreviousValue, setFormattedPreviousValue] = useState("");
  const [formattedChange, setFormattedChange] = useState("");

  useEffect(() => {
    const isFirstValueZero = metric.previousValue === 0;
    const isCurrentValuePositive = metric.value > 0;

    setIsIncrease(
      metric.change > 0 || (isFirstValueZero && isCurrentValuePositive)
    );
    setIsDecrease(metric.change < 0);
    setFormattedValue(
      `${metric.prefix || ""}${metric.value}${metric.suffix || ""}`
    );
    setFormattedPreviousValue(
      `${metric.prefix || ""}${metric.previousValue}${metric.suffix || ""}`
    );
    setFormattedChange(
      metric.previousValue === 0 && metric.value !== 0
        ? "100%"
        : `${Math.abs(metric.change)}%`
    );
  }, [metric]);
  return (
    <div className="px-4 py-5 sm:p-6">
      <dt className="text-base font-normal text-gray-300">{metric.label}</dt>
      <dd className="mt-1">
        <div className="flex items-baseline justify-between">
          <div className="flex items-baseline text-2xl font-semibold text-white">
            {formattedValue}
            <span className="ml-2 text-sm font-medium text-gray-500">
              from {formattedPreviousValue}
            </span>
          </div>

          <div
            className={classNames(
              isIncrease
                ? "bg-green-900 text-green-200"
                : isDecrease
                ? "bg-red-900 text-red-200"
                : "bg-gray-900 text-gray-200",
              "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium"
            )}
          >
            {isIncrease ? (
              <ArrowUpIcon
                aria-hidden="true"
                className="-ml-1 mr-0.5 h-5 w-5 shrink-0 self-center text-green-500"
              />
            ) : isDecrease ? (
              <ArrowDownIcon
                aria-hidden="true"
                className="-ml-1 mr-0.5 h-5 w-5 shrink-0 self-center text-red-500"
              />
            ) : null}

            <span className="sr-only">
              {isIncrease
                ? "Increased"
                : isDecrease
                ? "Decreased"
                : "No change"}{" "}
              by
            </span>
            {formattedChange}
          </div>
        </div>
      </dd>
    </div>
  );
};
