import axios from "axios";
import { PromptsService } from "../../../domain/src/leads/services/promptsService";
import { Prompt } from "../../../domain/src/leads/entities/prompt";
import { TokenStorageService } from "../../../domain/src/auth/services/tokenStorageService";
import { IRestApiConfig } from "../../auth/services/RestApiConfig";
import { PaginatedResponse } from "../../../domain/src/leads/entities/paginatedResponse";

export class RESTPromptsService implements PromptsService {
  private readonly baseUrl = "/api/admin/prompts";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly config: IRestApiConfig
  ) {}

  async getPrompts(
    page: number,
    perPage: number
  ): Promise<PaginatedResponse<Prompt>> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          page_size: perPage,
        },
      }
    );

    return {
      results: response.data.results.map((prompt: any) =>
        Prompt.fromJson(prompt)
      ),
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
    };
  }
}
