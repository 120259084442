import { Lead } from "../../../domain/src/leads";
import {
  LeadFilterOptions,
  LeadsPaginatedResponse,
} from "../../../domain/src/leads/repositories/contactsRepository";
import { LeadsRepository } from "../../../domain/src/leads/repositories/leadsRepository";
import { LeadsService } from "../../../domain/src/leads/services/leadsService";

export class LeadsRepositoryImpl implements LeadsRepository {
  constructor(private readonly leadsService: LeadsService) {}

  async getLeads(
    page: number = 1,
    options?: LeadFilterOptions
  ): Promise<LeadsPaginatedResponse> {
    return await this.leadsService.getLeads(page, options);
  }

  async getLead(id: string): Promise<Lead> {
    return await this.leadsService.getLead(id);
  }
}
