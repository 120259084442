import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "lucide-react";
import React, { Fragment } from "react";

interface SideDrawerProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  onClose?: () => void;
  footerActions?: React.ReactNode;
  isOpen: boolean;
}

export const SideDrawer: React.FC<SideDrawerProps> = ({
  title,
  children,
  onClose,
  footerActions,
  isOpen,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={onClose || (() => {})}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                  <div className="flex h-full flex-col bg-gray-800 shadow-xl">
                    <div className="flex justify-between items-center px-4 sm:px-6 py-6 border-b border-gray-700">
                      <Dialog.Title className="text-base font-semibold text-gray-100">
                        {title}
                      </Dialog.Title>
                      {onClose && (
                        <button
                          type="button"
                          className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={onClose}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                    <div className="relative flex-1 py-2 px-4 sm:px-6 overflow-y-auto">
                      {children}
                    </div>
                    {footerActions && (
                      <div className="flex-shrink-0 border-t border-gray-700 px-4 py-5 sm:px-6">
                        {footerActions}
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
