import axios from "axios";
import { ApiConfigStorageService } from "../../../domain/src/auth/services/apiConfigStorageService";

export interface IRestApiConfig {
  getPrimaryApiUrl(): string;
  getSecondaryApiUrl(): string | null;
  setConfig(config: { apiUrl: string }): void;
  removeConfig(): void;
  checkRegistration(slug?: string): Promise<{ apiUrl: string }>;
}

export class RestApiConfig implements IRestApiConfig {
  private readonly apiUrl: string;
  private readonly apiConfigStorage: ApiConfigStorageService;

  constructor(
    initialApiUrl: string,
    primaryApiConfigStorage: ApiConfigStorageService
  ) {
    this.apiUrl = initialApiUrl;
    this.apiConfigStorage = primaryApiConfigStorage;
  }

  getPrimaryApiUrl(): string {
    return this.apiUrl;
  }

  getSecondaryApiUrl(): string | null {
    return this.apiConfigStorage.getApiConfig()?.apiUrl || null;
  }

  setConfig(config: { apiUrl: string }): void {
    console.log("setting config ", config);
    this.apiConfigStorage.setApiConfig(config);
  }

  removeConfig(): void {
    this.apiConfigStorage.removeApiConfig();
  }

  async checkRegistration(slug?: string): Promise<{ apiUrl: string }> {
    try {
      const response = await axios.get(`${this.apiUrl}/configs/`, {
        params: { slug },
      });
      this.setConfig(response.data.app_config);
      return response.data.app_config;
    } catch (error) {
      console.error("Error fetching configuration:", error);
      throw error;
    }
  }
}
