export class PromptChoice {
  id: number;
  text: string;
  value: string;

  constructor(data: any) {
    this.id = data.id;
    this.text = data.text;
    this.value = data.value;
  }

  static fromJson(json: any): PromptChoice {
    return new PromptChoice(json);
  }
}
