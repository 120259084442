import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDi} from "../../DiContext";
import {Lead} from "../../domain/src/leads/entities";

type ContextType = {
  lead: Lead | null;
  loading: boolean;
  loaded: boolean;
  getLead: (id: string) => Promise<void>;
};

const LeadDetailsContext = createContext<ContextType>({
  lead: null,
  loading: false,
  loaded: false,
  getLead: async () => {},
});

export const useLeadDetails = () => {
  const context = useContext(LeadDetailsContext);
  if (!context) {
    throw new Error("useLeadDetails must be used within a LeadDetailsProvider");
  }
  return context;
};

type PropsType = {
  children: React.ReactElement;
};

const LeadDetailsProvider = ({ children }: PropsType) => {
  const [lead, setLead] = useState<Lead | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { leadsRepository } = useDi();
  const { id } = useParams();

  const getLead = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const leadData = await leadsRepository.getLead(id);
      setLead(leadData);
      setLoaded(true);
    } catch (err) {
      alert("Something went wrong. Please refresh");
    } finally {
      setLoading(false);
    }
  }, [leadsRepository]);

  useEffect(() => {
    if (id) {
      getLead(id);
    }
  }, [id, getLead]);

  return (
    <LeadDetailsContext.Provider value={{ lead, loading, loaded, getLead }}>
      {children}
    </LeadDetailsContext.Provider>
  );
};

export default LeadDetailsProvider;
