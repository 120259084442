import { Attribute, Lead } from "../../../domain/src/leads/entities";
import { LeadDetailsPresenter } from "./LeadDetailsPresenter";
import { StatusVariant } from "../types";

export class DefaultLeadDetailsPresenter implements LeadDetailsPresenter {
  formatAttributeValue(
    value: Lead["attributes"][string] | undefined,
    dataType: Attribute["dataType"]
  ): string {
    if (value === null || value === undefined) return "Not Provided";

    switch (dataType) {
      case "boolean":
        return value ? "Yes" : "No";
      case "date":
        return new Date(value as string).toLocaleDateString();
      case "list_of_strings":
        return (value as string[]).join(", ");
      case "list_of_integers":
        return (value as number[]).join(", ");
      default:
        return String(value);
    }
  }

  getStatusVariant(status: string): StatusVariant {
    switch (status.toLowerCase()) {
      case "sent":
        return "green";
      case "failed":
        return "red";
      case "pending":
        return "yellow";
      default:
        return "gray";
    }
  }
}
