import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDi } from "../../DiContext";
import { NotificationStatus } from "../../domain/src/leads/entities/notificationStatus";
import { useLeadDetails } from "./LeadDetailsProvider";
import { NotificationStatusUpdateFields } from "../../domain/src/leads/services/notificationStatusService";

interface ContextType {
  notificationStatuses: NotificationStatus[];
  loading: boolean;
  error: string | null;
  refreshNotificationStatuses: () => Promise<void>;
  updateNotificationStatus: (
    id: string,
    fields: NotificationStatusUpdateFields
  ) => Promise<void>;
}

export const NotificationStatusesForLeadContext = createContext<ContextType>({
  notificationStatuses: [],
  loading: false,
  error: null,
  refreshNotificationStatuses: async () => {},
  updateNotificationStatus: async () => {},
});

export const useNotificationStatusesForLead = () => {
  const context = useContext(NotificationStatusesForLeadContext);
  if (!context) {
    throw new Error(
      "useNotificationStatusesForLead must be used within a NotificationStatusesForLeadProvider"
    );
  }
  return context;
};

interface Props {
  children: ReactNode;
}

const NotificationStatusesForLeadProvider = ({ children }: Props) => {
  const { notificationStatusRepository } = useDi();
  const { lead } = useLeadDetails();

  const [notificationStatuses, setNotificationStatuses] = useState<
    NotificationStatus[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchNotificationStatuses = useCallback(async () => {
    if (!lead) return;
    setLoading(true);
    setError(null);
    try {
      const response =
        await notificationStatusRepository.findNotificationStatuses({
          contact_id: lead.id.toString(),
          status: "pending",
        });
      setNotificationStatuses(response.items);
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An error occurred while fetching notification statuses"
      );
    } finally {
      setLoading(false);
    }
  }, [notificationStatusRepository, lead]);

  const updateNotificationStatus = useCallback(
    async (id: string, fields: NotificationStatusUpdateFields) => {
      setLoading(true);
      setError(null);
      try {
        await notificationStatusRepository.updateNotificationStatus(id, fields);
        await fetchNotificationStatuses();
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : "An error occurred while updating notification status"
        );
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [notificationStatusRepository, fetchNotificationStatuses]
  );

  useEffect(() => {
    fetchNotificationStatuses();
  }, [lead, fetchNotificationStatuses]);

  return (
    <NotificationStatusesForLeadContext.Provider
      value={{
        notificationStatuses,
        loading,
        error,
        refreshNotificationStatuses: fetchNotificationStatuses,
        updateNotificationStatus,
      }}
    >
      {children}
    </NotificationStatusesForLeadContext.Provider>
  );
};

export default NotificationStatusesForLeadProvider;
