import { FC } from "react";
import { useAttributes } from "../../../providers/admin/AttributesProvider";
import LeadDetailsCard from "./LeadDetailsCard";
import LeadFitnessProfile from "./LeadFitnessProfile";
import { useLeadDetails } from "../../../providers/admin/LeadDetailsProvider";
import LeadWorkouts from "./LeadWorkouts";
import LeadCommunicationLogs from "./LeadCommunicationLogs";
import {
  PROFILE_OVERVIEW_KEYS,
  ATTRIBUTE_LABELS,
} from "../constants/leadAttributeMappings";
import { LeadPendingMessages } from "./LeadPendingMessages";
import { DefaultLeadDetailsPresenter } from "../presenters/DefaultLeadDetailsPresenter";

const leadPresenter = new DefaultLeadDetailsPresenter();

interface ViewLeadDetailsProps {}

const ViewLeadDetails: FC<ViewLeadDetailsProps> = () => {
  const { lead, loading } = useLeadDetails();
  const { attributes } = useAttributes();

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="animate-pulse">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gray-800 rounded-lg shadow-md p-6">
              <div className="h-6 bg-gray-700 rounded w-1/2 mb-4"></div>
              <div className="space-y-4">
                {[1, 2, 3].map((i) => (
                  <div key={i}>
                    <div className="h-4 bg-gray-700 rounded w-1/4 mb-2"></div>
                    <div className="h-4 bg-gray-700 rounded w-3/4"></div>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-gray-800 rounded-lg shadow-md p-6">
              <div className="h-6 bg-gray-700 rounded w-1/2 mb-4"></div>
              <div className="grid grid-cols-2 gap-4">
                {[1, 2, 3, 4].map((i) => (
                  <div key={i}>
                    <div className="h-4 bg-gray-700 rounded w-1/2 mb-2"></div>
                    <div className="h-4 bg-gray-700 rounded w-3/4"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!lead) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="w-full md:w-1/2">
          <LeadDetailsCard
            title="Profile Overview"
            subtitle="Contact and registration details"
          >
            <div className="space-y-4">
              {PROFILE_OVERVIEW_KEYS.map((key) => {
                const attribute = attributes.find((attr) => attr.name === key);
                return (
                  <div key={key}>
                    <h3 className="text-base font-bold text-gray-100">
                      {attribute?.label || ATTRIBUTE_LABELS[key]}
                    </h3>
                    <p className="mt-1 text-sm text-gray-300">
                      {leadPresenter.formatAttributeValue(
                        lead.attributes[key],
                        attribute?.dataType || "string"
                      )}
                    </p>
                  </div>
                );
              })}
              <div>
                <h3 className="text-base font-bold text-gray-100">
                  Created At
                </h3>
                <p className="mt-1 text-sm text-gray-300">
                  {new Date(lead.createdAt).toLocaleDateString()}
                </p>
              </div>
            </div>
          </LeadDetailsCard>

          <div className="mt-6">
            <LeadFitnessProfile
              lead={lead}
              attributes={attributes}
              fitnessProfileKeys={new Map(Object.entries(ATTRIBUTE_LABELS))}
              title="Workout Details"
              subtitle="Other attributes and preferences"
              excludeFitnessProfile={true}
            />
          </div>
        </div>

        <div className="w-full md:w-1/2 space-y-6">
          <LeadFitnessProfile
            lead={lead}
            attributes={attributes}
            fitnessProfileKeys={new Map(Object.entries(ATTRIBUTE_LABELS))}
            title="Fitness Profile"
            subtitle="Physical attributes and fitness goals"
            excludeFitnessProfile={false}
          />
          <LeadCommunicationLogs />
          <LeadPendingMessages />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <LeadWorkouts />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ViewLeadDetails;
