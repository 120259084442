import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDi } from "../../DiContext";
import { CommunicationLog } from "../../domain/src/leads/services/communicationLogsService";
import { useLeadDetails } from "./LeadDetailsProvider";

interface ContextType {
  communicationLogs: CommunicationLog[];
  loading: boolean;
  error: string | null;
  refreshCommunicationLogs: () => Promise<void>;
}

export const CommunicationLogsForLeadContext = createContext<ContextType>({
  communicationLogs: [],
  loading: false,
  error: null,
  refreshCommunicationLogs: async () => {},
});

export const useCommunicationLogsForLead = () => {
  const context = useContext(CommunicationLogsForLeadContext);
  if (!context) {
    throw new Error(
      "useCommunicationLogsForLead must be used within a CommunicationLogsForLeadProvider"
    );
  }
  return context;
};

interface Props {
  children: ReactNode;
}

const CommunicationLogsForLeadProvider = ({ children }: Props) => {
  const { communicationLogsRepository } = useDi();
  const { lead } = useLeadDetails();

  const [communicationLogs, setCommunicationLogs] = useState<
    CommunicationLog[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCommunicationLogs = useCallback(async () => {
    if (!lead) return;
    setLoading(true);
    setError(null);
    try {
      const logsResponse =
        await communicationLogsRepository.getCommunicationLogs(1, 100, {
          sent_to: lead?.email.toString() ?? "",
        });
      setCommunicationLogs(logsResponse.results);
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An error occurred while fetching communication logs"
      );
    } finally {
      setLoading(false);
    }
  }, [communicationLogsRepository, lead]);

  useEffect(() => {
    fetchCommunicationLogs();
  }, [lead, fetchCommunicationLogs]);

  return (
    <CommunicationLogsForLeadContext.Provider
      value={{
        communicationLogs,
        loading,
        error,
        refreshCommunicationLogs: fetchCommunicationLogs,
      }}
    >
      {children}
    </CommunicationLogsForLeadContext.Provider>
  );
};

export default CommunicationLogsForLeadProvider;
