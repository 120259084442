import { AttributesRepository } from "../../../domain/src/leads/repositories/attributesRepository";
import { AttributesService } from "../../../domain/src/leads/services/attributesService";
import { Attribute } from "../../../domain/src/leads/entities/attribute";

export class AttributesRepositoryImpl implements AttributesRepository {
  constructor(private readonly attributesService: AttributesService) {}

  async getAttributes(): Promise<Attribute[]> {
    return this.attributesService.getAttributes();
  }
}
