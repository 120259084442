import { ValueMetricCard } from "./ValueMetricCard";
import Loader from "../../../components/common/Loader";
import { useViewLeadsAnalytics } from "../../../providers/admin/ViewLeadsAnalyticsProvider";
import { useWorkoutsAnalytics } from "../../../providers/admin/WorkoutsAnalyticsProvider";

export const LeadMetricsRow = () => {
  const viewLeadsAnalyticsProvider = useViewLeadsAnalytics();
  const workoutsAnalyticsProvider = useWorkoutsAnalytics();

  const isLoading =
    workoutsAnalyticsProvider.metrics.isLoading ||
    viewLeadsAnalyticsProvider.metrics.contacts.isLoading;
  const error =
    workoutsAnalyticsProvider.metrics.error ||
    viewLeadsAnalyticsProvider.metrics.contacts.error;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 divide-y sm:divide-y-0 sm:divide-x divide-gray-600 bg-gray-800 rounded-lg">
      {isLoading ? (
        <div className="flex justify-center items-center p-6">
          <Loader />
        </div>
      ) : error ? (
        <div className="text-red-500 p-6">
          Error loading metrics: {error.message}
        </div>
      ) : workoutsAnalyticsProvider.metrics.data &&
        viewLeadsAnalyticsProvider.metrics.contacts.data ? (
        <>
          <ValueMetricCard
            metric={viewLeadsAnalyticsProvider.metrics.contacts.data}
          />
          <ValueMetricCard
            metric={workoutsAnalyticsProvider.metrics.data.totalWorkouts}
          />
          <ValueMetricCard
            metric={workoutsAnalyticsProvider.metrics.data.completedWorkouts}
          />
          <ValueMetricCard
            metric={workoutsAnalyticsProvider.metrics.data.completionRate}
          />
        </>
      ) : (
        <div className="text-gray-500 p-6">No metrics available</div>
      )}
    </div>
  );
};
