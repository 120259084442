import { Field, Input } from "@headlessui/react";
import React from "react";
import { useNotificationStatusesForLead } from "../../../providers/admin/NotificationStatusesForLeadProvider";
import { DefaultLeadDetailsPresenter } from "../presenters/DefaultLeadDetailsPresenter";
import LeadDetailsCard from "./LeadDetailsCard";
import Alert from "./shared/Alert";
import { Button } from "./shared/Button";
import MarkdownEditor from "./shared/MarkdownEditor";
import { MessageList } from "./shared/MessageList";
import StatusBadge from "./shared/StatusBadge";
import { SideDrawer } from "./SideDrawer";

const leadPresenter = new DefaultLeadDetailsPresenter();

export const LeadPendingMessages: React.FC = () => {
  const {
    notificationStatuses,
    loading: loadingStatuses,
    error,
    refreshNotificationStatuses,
    updateNotificationStatus,
  } = useNotificationStatusesForLead();
  const [selectedMessage, setSelectedMessage] = React.useState<any | null>(
    null
  );
  const [content, setContent] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSend = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateNotificationStatus(selectedMessage.id, {
        pending_message: content,
        pending_subject: subject,
      });
      await refreshNotificationStatuses();
    } catch (err) {
      console.error(err);
    } finally {
      setSelectedMessage(null);
      setLoading(false);
    }
  };

  if (loadingStatuses) {
    return (
      <LeadDetailsCard title="Pending Messages" subtitle="Loading messages...">
        <div className="animate-pulse space-y-4">
          {[1, 2].map((i) => (
            <div key={i} className="h-16 bg-gray-700 rounded"></div>
          ))}
        </div>
      </LeadDetailsCard>
    );
  }

  if (error) {
    return (
      <LeadDetailsCard title="Pending Messages">
        <div className="text-red-400">{error}</div>
      </LeadDetailsCard>
    );
  }

  const messageItems = notificationStatuses.map((message) => ({
    title: message.pending_subject || `Message #${message.id}`,
    metadata: [
      {
        label: "Scheduled for",
        value: new Date(message.scheduled_for).toLocaleString(),
      },
      {
        label: "Status",
        value: (
          <StatusBadge
            variant={leadPresenter.getStatusVariant(message.status)}
            status={message.status}
          />
        ),
      },
    ],
    onClick: () => {
      setSelectedMessage(message);
      setContent(message.pending_message || "");
      setSubject(message.pending_subject || "");
    },
  }));

  return (
    <>
      <LeadDetailsCard title="Pending Messages" subtitle="Draft messages">
        {notificationStatuses.length === 0 ? (
          <div className="text-gray-400">No pending messages</div>
        ) : (
          <MessageList items={messageItems} />
        )}
      </LeadDetailsCard>

      <SideDrawer
        isOpen={selectedMessage !== null}
        title={
          <div className="flex flex-col">
            <span className="text-lg font-medium">Edit Pending Message</span>
            {selectedMessage && (
              <div className="flex items-center gap-2 mt-1">
                <span className="text-sm text-gray-400">
                  Scheduled for{" "}
                  {new Date(selectedMessage.scheduled_for).toLocaleString()}
                </span>
                <StatusBadge
                  status={selectedMessage.status}
                  variant={leadPresenter.getStatusVariant(
                    selectedMessage.status
                  )}
                />
              </div>
            )}
          </div>
        }
        onClose={() => setSelectedMessage(null)}
        footerActions={
          <Button
            type="submit"
            onClick={handleSend}
            disabled={loading}
            className="w-full"
          >
            Save
          </Button>
        }
      >
        <div className="space-y-4">
          <Alert variant="info">
            This message is scheduled to be sent automatically. You can review
            and edit the content before it's sent.
          </Alert>

          <div className="space-y-2">
            <Field>
              <Input
                type="text"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Enter message subject..."
                className="block w-full rounded-md bg-gray-900 px-3 py-1.5 text-base text-gray-200 outline-1 -outline-offset-1 outline-gray-700 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"
              />
            </Field>
          </div>

          <div className="prose prose-invert max-w-none p-6 bg-gray-900 text-gray-200 rounded-lg">
            <MarkdownEditor
              value={content}
              onChange={setContent}
              placeholder="Write your message..."
            />
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
