export enum LeadAttributeKeys {
  EMAIL = "email",
  OFFER_PREFERENCE = "offer_preference",
  AGE = "age",
  GENDER = "gender",
  HEIGHT = "height",
  WEIGHT = "weight",
  CURRENT_ACTIVITY = "current_activity",
  GOAL = "goal",
  INJURIES = "injuries",
  MEDICAL_CLEARANCE = "medical_clearance",
  LIABILITY = "liability",
  NAME = "name",
  PACKAGE = "package",
}

export const ATTRIBUTE_LABELS: Record<string, string> = {
  [LeadAttributeKeys.EMAIL]: "Email",
  [LeadAttributeKeys.OFFER_PREFERENCE]: "Offer Preference",
  [LeadAttributeKeys.AGE]: "Age",
  [LeadAttributeKeys.GENDER]: "Gender",
  [LeadAttributeKeys.HEIGHT]: "Height",
  [LeadAttributeKeys.WEIGHT]: "Weight",
  [LeadAttributeKeys.CURRENT_ACTIVITY]: "Current Activity",
  [LeadAttributeKeys.GOAL]: "Goal",
  [LeadAttributeKeys.INJURIES]: "Injuries",
  [LeadAttributeKeys.MEDICAL_CLEARANCE]: "Medical Clearance",
  [LeadAttributeKeys.LIABILITY]: "Liability Waiver",
  [LeadAttributeKeys.NAME]: "Name",
  [LeadAttributeKeys.PACKAGE]: "Package",
};

export const PROFILE_OVERVIEW_KEYS = [
  LeadAttributeKeys.EMAIL,
  LeadAttributeKeys.OFFER_PREFERENCE,
  LeadAttributeKeys.AGE,
  LeadAttributeKeys.GENDER,
] as const;

export const FITNESS_PROFILE_KEYS = [
  LeadAttributeKeys.HEIGHT,
  LeadAttributeKeys.WEIGHT,
  LeadAttributeKeys.CURRENT_ACTIVITY,
  LeadAttributeKeys.GOAL,
  LeadAttributeKeys.INJURIES,
  LeadAttributeKeys.MEDICAL_CLEARANCE,
  LeadAttributeKeys.LIABILITY,
] as const;
