import { createContext, ReactNode, useEffect, useState } from "react";
import { useDi } from "../../DiContext";

interface AuthenticatedType {
  isAuthenticated: true;
}

interface NotAuthenticatedType {
  isAuthenticated: false;
}

export type ContextType = {
  authenticatedState: AuthenticatedType | NotAuthenticatedType;
  loaded: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
};

export const AuthContext = createContext<ContextType>({
  authenticatedState: { isAuthenticated: false },
  loaded: false,
  loading: false,
  login: async (email: string, password: string) => {},
  logout: () => {},
});

const AuthProvider = (props: { children: ReactNode }) => {
  const { authenticationRepository } = useDi();

  const [authenticatedState, setAuthenticatedState] = useState<
    AuthenticatedType | NotAuthenticatedType
  >({
    isAuthenticated: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const checkLoggedInState = async () => {
    setLoading(true);
    try {
      const result = await authenticationRepository.checkAndRefreshToken();

      setAuthenticatedState({
        isAuthenticated: result,
      });
    } catch (error) {
      setAuthenticatedState({
        isAuthenticated: false,
      });
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const login = async (email: string, password: string) => {
    setLoading(true);
    try {
      await authenticationRepository.login(email, password);
      setAuthenticatedState({
        isAuthenticated: true,
      });
    } catch (error) {
      alert("Invalid username and password");
      setAuthenticatedState({
        isAuthenticated: false,
      });
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const logout = async () => {
    if (authenticatedState.isAuthenticated) {
      try {
        await authenticationRepository.logout();
      } finally {
        setAuthenticatedState({
          isAuthenticated: false,
        });
      }
    }
  };

  useEffect(() => {
    checkLoggedInState();
  }, []); // Empty dependency array since we only want this to run once on mount

  return (
    <AuthContext.Provider
      value={{ authenticatedState, loading, login, logout, loaded }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
