import { User } from "../../../domain/src/auth/entities/user";
import { AuthenticationRepository } from "../../../domain/src/auth/repositories/authenticationRepository";
import { AuthenticationService } from "../../../domain/src/auth/services/authenticationService";

export class AuthenticationRepositoryImpl implements AuthenticationRepository {
  constructor(private readonly authService: AuthenticationService) {}

  async login(username: string, password: string): Promise<string> {
    return await this.authService.login(username, password);
  }

  async getCurrentUser(): Promise<User> {
    return this.authService.getCurrentUser();
  }

  async isAuthenticated(): Promise<boolean> {
    return this.authService.isAuthenticated();
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }

  async checkAndRefreshToken(): Promise<boolean> {
    try {
      // First refresh the token
      await this.authService.refreshCurrentToken();

      return true;
    } catch (error) {
      console.error("Error checking and refreshing token:", error);
      return false;
    }
  }
}
