import React, { createContext, useContext, useEffect, useState } from "react";
import { Attribute } from "../../domain/src/leads/entities/attribute";
import { useDi } from "../../DiContext";

interface AttributesContextType {
  attributes: Attribute[];
  loading: boolean;
  error: Error | null;
}

const AttributesContext = createContext<AttributesContextType>({
  attributes: [],
  loading: false,
  error: null,
});

export const useAttributes = () => useContext(AttributesContext);

interface AttributesProviderProps {
  children: React.ReactNode;
}

export const AttributesProvider: React.FC<AttributesProviderProps> = ({
  children,
}) => {
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { attributesRepository } = useDi();

  useEffect(() => {
    const fetchAttributes = async () => {
      setLoading(true);
      try {
        const data = await attributesRepository.getAttributes();
        setAttributes(data);
        setError(null);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Failed to fetch attributes")
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAttributes();
  }, [attributesRepository]);

  return (
    <AttributesContext.Provider value={{ attributes, loading, error }}>
      {children}
    </AttributesContext.Provider>
  );
};
