import { NotificationStatusRepository } from "../../../domain/src/leads/repositories/notificationStatusRepository";
import {
  NotificationService,
  NotificationStatusUpdateFields,
} from "../../../domain/src/leads/services/notificationStatusService";
import { NotificationStatus } from "../../../domain/src/leads/entities/notificationStatus";
import { NotificationStatusFilter } from "../../../domain/src/leads/entities/notificationStatusFilter";

export class NotificationStatusRepositoryImpl
  implements NotificationStatusRepository
{
  constructor(private readonly notificationService: NotificationService) {}

  async findNotificationStatuses(filter: NotificationStatusFilter): Promise<{
    items: NotificationStatus[];
    total: number;
  }> {
    return await this.notificationService.getNotificationStatuses(filter);
  }

  async updateNotificationStatus(
    id: string,
    fields: NotificationStatusUpdateFields
  ): Promise<NotificationStatus> {
    return await this.notificationService.updateNotificationStatus(id, fields);
  }
}
