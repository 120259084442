import { FC, useState } from "react";
import { useCommunicationLogsForLead } from "../../../providers/admin/CommunicationLogsForLeadProvider";
import LeadDetailsCard from "./LeadDetailsCard";
import { CommunicationLog } from "../../../domain/src/leads/services/communicationLogsService";
import { MessageList } from "./shared/MessageList";
import { SideDrawer } from "./SideDrawer";
import Markdown from "react-markdown";
import StatusBadge from "./shared/StatusBadge";
import { DefaultLeadDetailsPresenter } from "../presenters/DefaultLeadDetailsPresenter";

const leadPresenter = new DefaultLeadDetailsPresenter();

const LeadCommunicationLogs: FC = () => {
  const { communicationLogs, loading, error } = useCommunicationLogsForLead();
  const [selectedLog, setSelectedLog] = useState<CommunicationLog | null>(null);

  if (loading) {
    return (
      <LeadDetailsCard title="Outbound Messages" subtitle="Loading logs...">
        <div className="animate-pulse space-y-4">
          {[1, 2].map((i) => (
            <div key={i} className="h-16 bg-gray-700 rounded"></div>
          ))}
        </div>
      </LeadDetailsCard>
    );
  }

  if (error) {
    return (
      <LeadDetailsCard title="Outbound Messages">
        <div className="text-red-400">{error}</div>
      </LeadDetailsCard>
    );
  }

  if (!communicationLogs.length) {
    return (
      <LeadDetailsCard title="Outbound Messages">
        <div className="text-gray-400">No outbound messages found</div>
      </LeadDetailsCard>
    );
  }

  const messageItems = communicationLogs.map((log) => ({
    title: log.message_subject?.replace(/['"]/g, "") || `Message #${log.id}`,
    metadata: [
      {
        label: "Status",
        value: (
          <StatusBadge
            variant={leadPresenter.getStatusVariant(log.status)}
            status={log.status}
          ></StatusBadge>
        ),
      },
      { label: "Sent", value: <>{new Date(log.sent_at).toLocaleString()}</> },
    ],
    onClick: () => setSelectedLog(log),
  }));

  return (
    <>
      <LeadDetailsCard title="Outbound Messages" subtitle="Message history">
        <MessageList items={messageItems} />
      </LeadDetailsCard>

      <SideDrawer
        isOpen={selectedLog !== null}
        onClose={() => setSelectedLog(null)}
        title={
          <div className="flex flex-col">
            <span className="text-lg font-medium">
              {selectedLog?.message_subject?.replace(/['"]/g, "") || ""}
            </span>
            <div className="flex items-center gap-2 mt-1">
              <span className="text-sm text-gray-400">
                {selectedLog
                  ? new Date(selectedLog?.sent_at).toLocaleString()
                  : ""}
              </span>
              {selectedLog && (
                <StatusBadge
                  status={selectedLog.status}
                  variant={selectedLog.status === "sent" ? "green" : "yellow"}
                />
              )}
            </div>
          </div>
        }
      >
        <div className="prose prose-invert max-w-none p-6 bg-gray-900 rounded-lg">
          <Markdown className="whitespace-pre-line text-gray-200 [&>h1]:text-gray-100 [&>h2]:text-gray-100 [&>h3]:text-gray-100 [&>p]:text-gray-300 [&>ul]:text-gray-300 [&>ol]:text-gray-300">
            {selectedLog?.message_body || ""}
          </Markdown>
        </div>
      </SideDrawer>
    </>
  );
};

export default LeadCommunicationLogs;
