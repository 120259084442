import { FC, ReactNode } from "react";
import clsx from "clsx";
import { AlertTriangle, CheckCircle, Info, XCircle } from "lucide-react";

type AlertVariant = "info" | "warning" | "success" | "error";

interface AlertProps {
  variant?: AlertVariant;
  children: ReactNode;
  className?: string;
}

const variantStyles: Record<
  AlertVariant,
  { border: string; background: string; text: string; icon: typeof Info }
> = {
  info: {
    border: "border-blue-400/30",
    background: "bg-blue-400/10",
    text: "text-blue-400",
    icon: Info,
  },
  warning: {
    border: "border-yellow-400/30",
    background: "bg-yellow-400/10",
    text: "text-yellow-400",
    icon: AlertTriangle,
  },
  success: {
    border: "border-green-400/30",
    background: "bg-green-400/10",
    text: "text-green-400",
    icon: CheckCircle,
  },
  error: {
    border: "border-red-400/30",
    background: "bg-red-400/10",
    text: "text-red-400",
    icon: XCircle,
  },
};

const Alert: FC<AlertProps> = ({ variant = "info", children, className }) => {
  const styles = variantStyles[variant];
  const Icon = styles.icon;

  return (
    <div
      className={clsx(
        "border-l-4 p-4",
        styles.border,
        styles.background,
        className
      )}
    >
      <div className="flex">
        <div className="shrink-0">
          <Icon className={clsx("h-5 w-5", styles.text)} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={clsx("text-sm", styles.text)}>{children}</p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
