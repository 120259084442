import { ValueMetric } from "../../../domain/src/leads/entities/valueMetric";
import {
  WorkoutsAnalyticsService,
  WorkoutMetrics,
} from "../../../domain/src/leads/services/workoutsAnalyticsService";
import { TokenStorageService } from "../../../domain/src/auth";
import { IRestApiConfig } from "../../auth/services/RestApiConfig";
import axios from "axios";
import { LeadFilterOptions } from "../../../domain/src/leads/repositories/contactsRepository";
import { MetricResponse } from "../../types/metric_response";

interface WorkoutMetricsResponse {
  status: string;
  data: {
    total_workouts: MetricResponse;
    completed_workouts: MetricResponse;
    completion_rate: MetricResponse;
  };
}

export class RESTWorkoutsAnalyticsService implements WorkoutsAnalyticsService {
  private readonly baseUrl = "/api/admin/workouts/metrics/";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly restApiConfig: IRestApiConfig
  ) {}

  private transformMetric(metric: MetricResponse): ValueMetric {
    return {
      value: metric.value,
      previousValue: metric.previous_value,
      label: metric.label,
      previousLabel: metric.previous_label,
      change: metric.change,
      format: metric.format,
      prefix: metric.prefix,
      suffix: metric.suffix,
    };
  }

  async getWorkoutMetrics(
    options?: LeadFilterOptions
  ): Promise<WorkoutMetrics> {
    const response = await axios.get<WorkoutMetricsResponse>(
      `${this.restApiConfig.getSecondaryApiUrl()}${this.baseUrl}`,
      {
        headers: {
          Authorization: `Bearer ${this.tokenStorage.getToken()}`,
        },
        params: options,
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to fetch workout metrics");
    }

    return {
      totalWorkouts: this.transformMetric(response.data.data.total_workouts),
      completedWorkouts: this.transformMetric(
        response.data.data.completed_workouts
      ),
      completionRate: this.transformMetric(response.data.data.completion_rate),
    };
  }
}
