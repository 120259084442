import {
  Activity,
  CalendarDays,
  Ruler,
  Scale,
  Target,
  User2,
} from "lucide-react";
import { FC } from "react";
import { Lead } from "../../../domain/src";
import { Attribute } from "../../../domain/src/leads/entities/attribute";
import {
  ATTRIBUTE_LABELS,
  FITNESS_PROFILE_KEYS,
} from "../constants/leadAttributeMappings";
import { DefaultLeadDetailsPresenter } from "../presenters/DefaultLeadDetailsPresenter";
import LeadDetailsCard from "./LeadDetailsCard";

const leadPresenter = new DefaultLeadDetailsPresenter();

interface LeadFitnessProfileProps {
  lead: Lead;
  attributes: Attribute[];
  fitnessProfileKeys: Map<string, string>;
  title?: string;
  subtitle?: string;
  excludeFitnessProfile?: boolean;
}

const LeadFitnessProfile: FC<LeadFitnessProfileProps> = ({
  lead,
  attributes,
  fitnessProfileKeys,
  title = "Fitness Profile",
  subtitle = "Physical attributes and fitness goals",
  excludeFitnessProfile = false,
}) => {
  const getAttributeIcon = (attributeName: string) => {
    switch (attributeName) {
      case "weight":
        return <Scale className="w-5 h-5 text-gray-400 mr-2" />;
      case "height":
        return <Ruler className="w-5 h-5 text-gray-400 mr-2" />;
      case "gender":
        return <User2 className="w-5 h-5 text-gray-400 mr-2" />;
      case "age":
        return <CalendarDays className="w-5 h-5 text-gray-400 mr-2" />;
      case "current_activity":
        return <Activity className="w-5 h-5 text-gray-400 mr-2" />;
      case "goal":
        return <Target className="w-5 h-5 text-gray-400 mr-2" />;
      default:
        return null;
    }
  };

  const filteredAttributes = excludeFitnessProfile
    ? Object.entries(lead.attributes)
        .map(([name, value]) => {
          const attribute = attributes.find((attr) => attr.name === name);
          if (!attribute || FITNESS_PROFILE_KEYS.includes(name as any))
            return null;
          return attribute;
        })
        .filter(Boolean)
    : FITNESS_PROFILE_KEYS.map((key) =>
        attributes.find((attr) => attr.name === key)
      );

  if (filteredAttributes.length === 0) {
    return null;
  }

  return (
    <LeadDetailsCard title={title} subtitle={subtitle}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {filteredAttributes.map((attribute) => {
          if (!attribute) return null;
          const value = lead.attributes[attribute.name];

          return (
            <div key={attribute.name} className="flex flex-col">
              <span className="text-base font-bold text-gray-100 flex">
                {getAttributeIcon(attribute.name)}
                {ATTRIBUTE_LABELS[attribute.name]}
              </span>
              <span className="mt-1 text-sm text-gray-300">
                {leadPresenter.formatAttributeValue(value, attribute.dataType)}
              </span>
            </div>
          );
        })}
      </div>
    </LeadDetailsCard>
  );
};

export default LeadFitnessProfile;
