import { PromptsRepository } from "../../../domain/src/leads/repositories/promptsRepository";
import { PromptsService } from "../../../domain/src/leads/services/promptsService";
import { Prompt } from "../../../domain/src/leads/entities/prompt";
import { PaginatedResponse } from "../../../domain/src/leads/entities/paginatedResponse";

export class PromptsRepositoryImpl implements PromptsRepository {
  constructor(private readonly promptsService: PromptsService) {}

  async getPrompts(
    page: number,
    perPage: number
  ): Promise<PaginatedResponse<Prompt>> {
    return this.promptsService.getPrompts(page, perPage);
  }
}
