import axios from "axios";
import { AdminService } from "../../../domain/src/auth/services/adminService";
import { Tenant } from "../../../domain/src/auth/entities/tenant";
import { TokenStorageService } from "../../../domain/src/auth/services/tokenStorageService";
import { IRestApiConfig } from "../../auth/services/RestApiConfig";

export class RESTAdminService implements AdminService {
  private readonly baseUrl = "/api/auth";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly config: IRestApiConfig
  ) {}

  async getUserTenants(): Promise<Tenant[]> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getPrimaryApiUrl()}${this.baseUrl}/tenants`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.tenants.map((tenant: any) => ({
      id: tenant.id,
      name: tenant.name,
      slug: tenant.slug,
      appConfig: {
        logo: tenant.app_config?.logo || "",
        apiUrl: tenant.app_config?.apiUrl || "",
        logoUrl: tenant.app_config?.logoUrl || "",
        subtext: tenant.app_config?.subtext || "",
        themeMode: tenant.app_config?.themeMode || "light",
        backgroundImage: tenant.app_config?.backgroundImage || "",
        rudderDataPlaneUrl: tenant.app_config?.rudderDataPlaneUrl || "",
      },
      logoUrl: tenant.logoUrl,
    }));
  }

  async getUserRoles(): Promise<
    { id: number; name: string; description: string }[]
  > {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getPrimaryApiUrl()}${this.baseUrl}/roles`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
}
