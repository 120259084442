import { FC, ReactNode } from "react";

interface LeadDetailsCardProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
}

const LeadDetailsCard: FC<LeadDetailsCardProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <div className="bg-gray-800 rounded-lg shadow-md p-6">
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-100">{title}</h2>
        {subtitle && <p className="text-sm text-gray-300 mt-1">{subtitle}</p>}
      </div>
      <div className="border-t border-gray-600 pt-4">{children}</div>
    </div>
  );
};

export default LeadDetailsCard;
