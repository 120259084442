import { PromptChoice } from "./prompt_choice";

export class Prompt {
  id: number;
  text: string;
  promptType: string;
  hintText: string;
  popupText: string;
  popupLinkText: string;
  variableName: string;
  choices: PromptChoice[];
  isIdentifier: boolean;
  allowMultiple: boolean;
  validationRules: string[];
  createdAt: string;
  updatedAt: string;
  otherChoiceEnabled: boolean;
  otherChoiceText: string;
  otherChoiceValidation: string;

  constructor(data: any) {
    this.id = data.id;
    this.text = data.text;
    this.promptType = data.prompt_type;
    this.hintText = data.hint_text;
    this.popupText = data.popup_text;
    this.popupLinkText = data.popup_link_text;
    this.variableName = data.variable_name;
    this.choices =
      data.choices?.map((choice: any) => PromptChoice.fromJson(choice)) || [];
    this.isIdentifier = data.is_identifier;
    this.allowMultiple = data.allow_multiple;
    this.validationRules = data.validation_rules || [];
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.otherChoiceEnabled = data.other_choice_enabled;
    this.otherChoiceText = data.other_choice_text;
    this.otherChoiceValidation = data.other_choice_validation;
  }

  static fromJson(json: any): Prompt {
    return new Prompt(json);
  }
}
