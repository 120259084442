import { FC } from "react";

interface MessageListItemProps {
  title: string;
  metadata: { label: string; value: React.ReactNode }[];
  onClick: () => void;
}

export const MessageListItem: FC<MessageListItemProps> = ({
  title,
  metadata,
  onClick,
}) => (
  <div className="border border-gray-700 rounded-lg p-4">
    <button
      className="w-full flex justify-between items-center text-left"
      onClick={onClick}
    >
      <div>
        <span className="text-gray-200 font-medium">{title}</span>
        <div className="text-sm text-gray-400 mt-1">
          {metadata.map(({ label, value }) => (
            <div key={label}>
              {label}: {value}
            </div>
          ))}
        </div>
      </div>
      <span className="text-gray-400">▶</span>
    </button>
  </div>
);

interface MessageListProps {
  items: MessageListItemProps[];
}

export const MessageList: FC<MessageListProps> = ({ items }) => (
  <div className="space-y-4">
    {items.map((item, index) => (
      <MessageListItem key={index} {...item} />
    ))}
  </div>
);
