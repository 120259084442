import { CommunicationLogsRepository } from "../../../domain/src/leads/repositories/communicationLogsRepository";
import {
  CommunicationLogsService,
  CommunicationLog,
  CommunicationLogFilterOptions,
} from "../../../domain/src/leads/services/communicationLogsService";
import { PaginatedResponse } from "../../../domain/src/leads/entities/paginatedResponse";

export class CommunicationLogsRepositoryImpl
  implements CommunicationLogsRepository
{
  constructor(
    private readonly communicationLogsService: CommunicationLogsService
  ) {}

  async getCommunicationLogs(
    page: number,
    limit: number,
    options?: CommunicationLogFilterOptions
  ): Promise<PaginatedResponse<CommunicationLog>> {
    return this.communicationLogsService.getCommunicationLogs(
      page,
      limit,
      options
    );
  }

  async getCommunicationLog(id: string): Promise<CommunicationLog> {
    return this.communicationLogsService.getCommunicationLog(id);
  }
}
