import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { WorkoutMetrics } from "../../domain/src/leads/services/workoutsAnalyticsService";
import { LeadFilterOptions } from "../../domain/src/leads/repositories/contactsRepository";
import { useDi } from "../../DiContext";

interface MetricState {
  data: WorkoutMetrics | null;
  isLoading: boolean;
  error: Error | null;
}

interface WorkoutsAnalyticsContextValue {
  metrics: MetricState;
  getMetrics: (options?: LeadFilterOptions) => Promise<void>;
}

const WorkoutsAnalyticsContext = createContext<
  WorkoutsAnalyticsContextValue | undefined
>(undefined);

export const WorkoutsAnalyticsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { workoutsAnalyticsRepository } = useDi();
  const [metrics, setMetrics] = React.useState<MetricState>({
    data: null,
    isLoading: true,
    error: null,
  });

  const getMetrics = useCallback(
    async (options?: LeadFilterOptions) => {
      setMetrics((prev) => ({
        ...prev,
        isLoading: true,
        error: null,
      }));

      try {
        const workoutMetrics =
          await workoutsAnalyticsRepository.getWorkoutMetrics(options);
        setMetrics({
          data: workoutMetrics,
          isLoading: false,
          error: null,
        });
      } catch (err) {
        const error =
          err instanceof Error
            ? err
            : new Error("Failed to fetch workout metrics");
        setMetrics((prev) => ({
          ...prev,
          isLoading: false,
          error,
        }));
      }
    },
    [workoutsAnalyticsRepository]
  );

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  return (
    <WorkoutsAnalyticsContext.Provider
      value={{
        metrics,
        getMetrics,
      }}
    >
      {children}
    </WorkoutsAnalyticsContext.Provider>
  );
};

export const useWorkoutsAnalytics = () => {
  const context = useContext(WorkoutsAnalyticsContext);
  if (context === undefined) {
    throw new Error(
      "useWorkoutsAnalytics must be used within a WorkoutsAnalyticsProvider"
    );
  }
  return context;
};
