import { motion } from "framer-motion";
import { Dumbbell, Loader } from "lucide-react";
import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AdminContext } from "../../../providers/admin/AdminProvider";
import EmptyState from "./EmptyState";

const AdminTenantSelector: React.FC = () => {
  const { loading, tenants, error } = useContext(AdminContext);
  const navigate = useNavigate();

  if (!loading && tenants.length === 1) {
    return <Navigate to={`/admin/tenants/${tenants[0].slug}`} replace />;
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <Loader className="animate-spin text-white" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  if (tenants.length === 0) {
    return (
      <EmptyState
        icon={Dumbbell}
        title="No Gyms Found"
        description="Please contact a Fitcopilot team member for assistance."
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-8">
      <h1 className="text-white text-3xl font-bold mb-8 text-center">
        Select Your Gym
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
        {tenants.map((tenant) => (
          <motion.button
            key={tenant.slug}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              navigate(`/admin/tenants/${tenant.slug}`);
            }}
            className="bg-gray-800 rounded-xl p-6 flex flex-col items-center justify-center transition-colors duration-200 hover:bg-gray-700"
          >
            {tenant.appConfig.logoUrl ? (
              <img
                src={tenant.appConfig.logoUrl}
                alt={tenant.name}
                className="h-16 w-auto mb-4 object-contain"
              />
            ) : (
              <div className="h-16 w-16 bg-gray-700 rounded-full mb-4 flex items-center justify-center">
                <span className="text-2xl text-white">
                  {tenant.name.charAt(0)}
                </span>
              </div>
            )}
            <span className="text-white font-medium text-lg">
              {tenant.name}
            </span>
            {tenant.appConfig.subtext && (
              <span className="text-gray-400 text-sm mt-2">
                {tenant.appConfig.subtext}
              </span>
            )}
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default AdminTenantSelector;
