import {
  AuthenticationRepository,
  AuthenticationService,
  TokenStorageService,
} from "./domain/src/auth";
import { AdminRepository } from "./domain/src/auth/repositories/adminRepository";
import { AttributesRepository } from "./domain/src/leads/repositories/attributesRepository";
import { CommunicationLogsRepository } from "./domain/src/leads/repositories/communicationLogsRepository";
import { LeadsRepository } from "./domain/src/leads/repositories/leadsRepository";
import { PromptsRepository } from "./domain/src/leads/repositories/promptsRepository";
import { WorkoutsRepository } from "./domain/src/leads/repositories/workoutsRespository";
import { AttributesService } from "./domain/src/leads/services/attributesService";
import { CommunicationLogsService } from "./domain/src/leads/services/communicationLogsService";
import { LeadsService } from "./domain/src/leads/services/leadsService";
import { PromptsService } from "./domain/src/leads/services/promptsService";
import { WorkoutsService } from "./domain/src/leads/services/workoutsService";
import { AdminRepositoryImpl } from "./infrastructure/admin/repositories/adminRepositoryImpl";
import { RESTAdminService } from "./infrastructure/admin/services/RESTAdminService";
import { AttributesRepositoryImpl } from "./infrastructure/attributes/repositories/attributesRepositoryImpl";
import { RESTAttributesServiceImpl } from "./infrastructure/attributes/services/RESTAttributesServiceImpl";
import { AuthenticationRepositoryImpl } from "./infrastructure/auth/repositories/authenticationRepositoryImpl";
import { InMemoryApiConfigStorageService } from "./infrastructure/auth/services/inMemoryApiConfigStorageService";
import { LocalStorageTokenStorageService } from "./infrastructure/auth/services/localTokenStorageService";
import { RestApiConfig } from "./infrastructure/auth/services/RestApiConfig";
import { RESTAuthenticationService } from "./infrastructure/auth/services/RESTAuthenticationService";
import { CommunicationLogsRepositoryImpl } from "./infrastructure/leads/repositories/communicationLogsRepositoryImpl";
import { LeadsRepositoryImpl } from "./infrastructure/leads/repositories/leadsRepositoryImpl";
import { PromptsRepositoryImpl } from "./infrastructure/leads/repositories/promptsRepositoryImpl";
import { WorkoutsRepositoryImpl } from "./infrastructure/leads/repositories/workoutsRepositoryImpl";
import { RESTCommunicationLogsService } from "./infrastructure/leads/services/RESTCommunicationLogsService";
import { RESTLeadsService } from "./infrastructure/leads/services/RESTLeadsService";
import { RESTPromptsService } from "./infrastructure/leads/services/RESTPromptsService";
import { RESTWorkoutsService } from "./infrastructure/leads/services/RESTWorkoutsService";
import { LeadsAnalyticsService } from "./domain/src/leads/services/leadsAnalyticsService";
import { RESTLeadsAnalyticsService } from "./infrastructure/leads/services/RESTLeadsAnalyticsService";
import { LeadsAnalyticsRepository } from "./domain/src/leads/repositories/leadsAnalyticsRepository";
import { LeadsAnalyticsRepositoryImpl } from "./infrastructure/leads/repositories/leadsAnalyticsRepositoryImpl";
import { WorkoutsAnalyticsService } from "./domain/src/leads/services/workoutsAnalyticsService";
import { WorkoutsAnalyticsRepository } from "./domain/src/leads/repositories/workoutsAnalyticsRepository";
import { WorkoutsAnalyticsRepositoryImpl } from "./infrastructure/leads/repositories/workoutsAnalyticsRepositoryImpl";
import { RESTWorkoutsAnalyticsService } from "./infrastructure/leads/services/RESTWorkoutsAnalyticsService";
import { NotificationStatusRepository } from "./domain/src/leads/repositories/notificationStatusRepository";
import { NotificationService } from "./domain/src/leads/services/notificationStatusService";
import { RESTNotificationStatusService } from "./infrastructure/leads/services/RESTNotificationStatusService";
import { NotificationStatusRepositoryImpl } from "./infrastructure/leads/repositories/notificationStatusRepositoryImpl";

export interface Container {
  tokenStorage: TokenStorageService;
  authenticationService: AuthenticationService;
  authenticationRepository: AuthenticationRepository;
  leadsService: LeadsService;
  leadsRepository: LeadsRepository;
  restApiConfig: RestApiConfig;
  adminRepository: AdminRepository;
  attributesRepository: AttributesRepository;
  attributesService: AttributesService;
  workoutsRepository: WorkoutsRepository;
  workoutsService: WorkoutsService;
  communicationLogsRepository: CommunicationLogsRepository;
  communicationLogsService: CommunicationLogsService;
  promptsRepository: PromptsRepository;
  promptsService: PromptsService;
  leadsAnalyticsService: LeadsAnalyticsService;
  leadsAnalyticsRepository: LeadsAnalyticsRepository;
  workoutsAnalyticsRepository: WorkoutsAnalyticsRepository;
  workoutsAnalyticsService: WorkoutsAnalyticsService;
  notificationStatusRepository: NotificationStatusRepository;
  notificationStatusService: NotificationService;
}

export const createContainer = (): Container => {
  const tokenStorage = new LocalStorageTokenStorageService();
  const apiConfigStorage = new InMemoryApiConfigStorageService();
  const restApiConfig = new RestApiConfig(
    process.env.REACT_APP_API_URL || "http://localhost:3000",
    apiConfigStorage
  );
  const authenticationService = new RESTAuthenticationService(
    tokenStorage,
    restApiConfig
  );
  const authenticationRepository = new AuthenticationRepositoryImpl(
    authenticationService
  );
  const leadsService = new RESTLeadsService(tokenStorage, restApiConfig);
  const leadsRepository = new LeadsRepositoryImpl(leadsService);
  const adminService = new RESTAdminService(tokenStorage, restApiConfig);
  const adminRepository = new AdminRepositoryImpl(adminService);
  const workoutsService = new RESTWorkoutsService(tokenStorage, restApiConfig);
  const workoutsRepository = new WorkoutsRepositoryImpl(workoutsService);
  const attributesService = new RESTAttributesServiceImpl(
    tokenStorage,
    restApiConfig
  );
  const attributesRepository = new AttributesRepositoryImpl(attributesService);
  const communicationLogsService = new RESTCommunicationLogsService(
    tokenStorage,
    restApiConfig
  );
  const communicationLogsRepository = new CommunicationLogsRepositoryImpl(
    communicationLogsService
  );
  const promptsService = new RESTPromptsService(tokenStorage, restApiConfig);
  const promptsRepository = new PromptsRepositoryImpl(promptsService);
  const leadsAnalyticsService = new RESTLeadsAnalyticsService(
    tokenStorage,
    restApiConfig
  );
  const leadsAnalyticsRepository = new LeadsAnalyticsRepositoryImpl(
    leadsAnalyticsService
  );
  const workoutsAnalyticsService = new RESTWorkoutsAnalyticsService(
    tokenStorage,
    restApiConfig
  );
  const workoutsAnalyticsRepository = new WorkoutsAnalyticsRepositoryImpl(
    workoutsAnalyticsService
  );
  const notificationStatusService = new RESTNotificationStatusService(
    tokenStorage,
    restApiConfig
  );
  const notificationStatusRepository = new NotificationStatusRepositoryImpl(
    notificationStatusService
  );

  return {
    tokenStorage,
    authenticationService,
    authenticationRepository,
    leadsService,
    leadsRepository,
    restApiConfig,
    adminRepository,
    attributesRepository,
    attributesService,
    workoutsRepository,
    workoutsService,
    communicationLogsRepository,
    communicationLogsService,
    promptsRepository,
    promptsService,
    leadsAnalyticsService,
    leadsAnalyticsRepository,
    workoutsAnalyticsRepository,
    workoutsAnalyticsService,
    notificationStatusRepository,
    notificationStatusService,
  };
};
