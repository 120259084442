import { Outlet } from "react-router-dom";

const TenantsLayout = () => {
  return (
    <div className="h-full bg-gray-900 flex flex-col overflow-y-auto">
      <Outlet />
    </div>
  );
};

export default TenantsLayout;
