import { createContext, ReactNode, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useDi } from "../../DiContext";

type ConfigType = {
  apiUrl: string;
};

type TenantConfigContextType = {
  loading: boolean;
  error: string | null;
  config: ConfigType | null;
};

export const TenantConfigContext = createContext<TenantConfigContextType>({
  loading: true,
  error: null,
  config: null,
});

interface TenantConfigProviderProps {
  children: ReactNode;
}

const TenantConfigProvider = ({ children }: TenantConfigProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [config, setConfig] = useState<ConfigType | null>(null);

  const { slug } = useParams();
  const { restApiConfig } = useDi();

  useEffect(() => {
    const loadConfig = async () => {
      if (!slug) {
        setError("No tenant specified");
        setLoading(false);
        return;
      }

      try {
        const response = await restApiConfig.checkRegistration(slug);
        setConfig(response);
        restApiConfig.setConfig(response);
        setError(null);
      } catch (err) {
        console.error("Error loading tenant config:", err);
        setError("Failed to load tenant configuration");
      } finally {
        setLoading(false);
      }
    };

    loadConfig();
  }, [slug, restApiConfig]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Navigate to="/admin/tenants" replace />;
  }

  return (
    <TenantConfigContext.Provider
      value={{
        loading,
        error,
        config,
      }}
    >
      {children}
    </TenantConfigContext.Provider>
  );
};

export default TenantConfigProvider;
