import { Route, Routes } from "react-router-dom";
import { AttributesProvider } from "../../../providers/admin/AttributesProvider";
import LeadDetailsProvider from "../../../providers/admin/LeadDetailsProvider";
import ViewLeadDetails from "../components/ViewLeadDetails";
import { LeadLayout } from "../components/LeadLayout";
import LeadWorkoutsProvider from "../../../providers/admin/LeadWorkoutsProvider";
import CommunicationLogsForLeadProvider from "../../../providers/admin/CommunicationLogsForLeadProvider";
import NotificationStatusesForLeadProvider from "../../../providers/admin/NotificationStatusesForLeadProvider";

export const LeadRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <LeadDetailsProvider>
            <AttributesProvider>
              <LeadWorkoutsProvider>
                <CommunicationLogsForLeadProvider>
                  <NotificationStatusesForLeadProvider>
                    <LeadLayout />
                  </NotificationStatusesForLeadProvider>
                </CommunicationLogsForLeadProvider>
              </LeadWorkoutsProvider>
            </AttributesProvider>
          </LeadDetailsProvider>
        }
      >
        <Route index element={<ViewLeadDetails />} />
      </Route>
    </Routes>
  );
};

export default LeadRoutes;
