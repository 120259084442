import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PageLayoutWithHeader from "../../../components/layouts/PageLayoutWithHeader";
import LeadsProvider, {
  useLeads,
} from "../../../providers/admin/LeadsProvider";
import LeadRoutes from "../routes/LeadRoutes";
import ViewLeads from "./ViewLeads";
import { PromptsProvider } from "../../../providers/admin/PromptsProvider";
import { AttributesProvider } from "../../../providers/admin/AttributesProvider";
import { ViewLeadsAnalyticsProvider } from "../../../providers/admin/ViewLeadsAnalyticsProvider";
import { WorkoutsAnalyticsProvider } from "../../../providers/admin/WorkoutsAnalyticsProvider";

const AdminHomeMenu = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-6xl mx-auto">
      <motion.div
        key="admin-grid"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.3 }}
        className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-8 p-8"
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate("leads")}
          className="bg-gray-800 hover:bg-gray-700 rounded-xl p-10 flex flex-col items-center justify-center transition-colors duration-200 aspect-square text-white"
        >
          <span className="text-6xl mb-4 text-white">
            <FontAwesomeIcon icon={faUserPlus} />
          </span>
          <span className="font-medium text-xl text-white">Leads</span>
        </motion.button>
        {/* More menu items will be added here later */}
      </motion.div>
    </div>
  );
};

const AdminHomeLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <PageLayoutWithHeader title="Your Leads" subtitle={``}>
      {children}
    </PageLayoutWithHeader>
  );
};

const AdminHome: React.FC = () => {
  return (
    <div className="bg-gray-900 text-white">
      <Routes>
        <Route index element={<AdminHomeMenu />} />
        <Route
          path="leads"
          element={
            <LeadsProvider>
              <ViewLeadsAnalyticsProvider>
                <WorkoutsAnalyticsProvider>
                  <PromptsProvider>
                    <AttributesProvider>
                      <AdminHomeLayout>
                        <ViewLeads />
                      </AdminHomeLayout>
                    </AttributesProvider>
                  </PromptsProvider>
                </WorkoutsAnalyticsProvider>
              </ViewLeadsAnalyticsProvider>
            </LeadsProvider>
          }
        />
        <Route path="leads/:id" element={<LeadRoutes />} />
        <Route path="*" element={<div>404 - Page not found</div>} />
      </Routes>
    </div>
  );
};

export default AdminHome;
