import { FC, useState } from "react";
import { useLeadWorkouts } from "../../../providers/admin/LeadWorkoutsProvider";
import LeadDetailsCard from "./LeadDetailsCard";
import Markdown from "react-markdown";
import { SideDrawer } from "./SideDrawer";
import { Workout } from "../../../domain/src/leads/entities/workout";

const LeadWorkouts: FC = () => {
  const { workouts, loading, error } = useLeadWorkouts();
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | null>(null);

  if (loading) {
    return (
      <LeadDetailsCard title="Workouts" subtitle="Loading workouts...">
        <div className="animate-pulse space-y-4">
          {[1, 2].map((i) => (
            <div key={i} className="h-16 bg-gray-700 rounded"></div>
          ))}
        </div>
      </LeadDetailsCard>
    );
  }

  if (error) {
    return (
      <LeadDetailsCard title="Workouts">
        <div className="text-red-400">{error}</div>
      </LeadDetailsCard>
    );
  }

  if (!workouts.length) {
    return (
      <LeadDetailsCard title="Workouts">
        <div className="text-gray-400">No workouts found</div>
      </LeadDetailsCard>
    );
  }

  return (
    <>
      <LeadDetailsCard title="Workouts" subtitle="Assigned workout plans">
        <div className="space-y-4">
          {workouts.map((workout) => (
            <div
              key={workout.id}
              className="border border-gray-700 rounded-lg p-4"
            >
              <button
                className="w-full flex justify-between items-center text-left"
                onClick={() => setSelectedWorkout(workout)}
              >
                <div>
                  <span className="text-gray-200 font-medium">
                    {workout.workoutTitle || `Workout #${workout.id}`}
                  </span>
                  <div className="text-sm text-gray-400 mt-1">
                    {new Date(workout.createdAt).toLocaleString()}
                  </div>
                </div>
                <span className="text-gray-400">▶</span>
              </button>
            </div>
          ))}
        </div>
      </LeadDetailsCard>

      <SideDrawer
        isOpen={selectedWorkout !== null}
        onClose={() => setSelectedWorkout(null)}
        title={
          <>
            <div className="flex flex-col p-4">
              <span className="text-lg font-medium text-gray-100">
                {selectedWorkout?.workoutTitle || "Untitled Workout"}
              </span>
              {selectedWorkout && (
                <span className="text-sm text-gray-400 mt-1">
                  {new Date(selectedWorkout.createdAt).toLocaleString()}
                </span>
              )}
            </div>
          </>
        }
      >
        <div className="prose prose-invert max-w-none p-6 bg-gray-900 rounded-lg">
          <Markdown className="whitespace-pre-line text-gray-200 [&>h1]:text-gray-100 [&>h2]:text-gray-100 [&>h3]:text-gray-100 [&>p]:text-gray-300 [&>ul]:text-gray-300 [&>ol]:text-gray-300">
            {selectedWorkout?.textFormat || ""}
          </Markdown>
        </div>
      </SideDrawer>
    </>
  );
};

export default LeadWorkouts;
