import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDi } from "./DiContext";
import AuthProtected from "./modules/auth/AuthProtected";
import AdminLayout from "./modules/tenants/components/AdminLayout";
import TenantsRoutes from "./modules/tenants/routes/TenantsRoutes";
import AdminProvider from "./providers/admin/AdminProvider";
import Login from "./views/admin/Login";
import { AuthContext } from "./providers/admin/AuthProvider";

const AdminApp: React.FC = () => {
  const { adminRepository } = useDi();
  const { authenticatedState, loaded } = useContext(AuthContext);
  return (
    <Routes>
      <Route
        path="/"
        element={
          loaded && authenticatedState.isAuthenticated ? (
            <Navigate to="/admin/tenants" replace />
          ) : (
            <Login />
          )
        }
      />
      <Route
        path="/admin"
        element={
          <AuthProtected>
            <AdminProvider adminRepository={adminRepository}>
              <AdminLayout />
            </AdminProvider>
          </AuthProtected>
        }
      >
        <Route path="*" element={<TenantsRoutes />} />
      </Route>
      {/* Catch any unmatched routes and redirect to root */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AdminApp;
