import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { ValueMetric } from "../../domain/src/leads/entities/valueMetric";
import { LeadFilterOptions } from "../../domain/src/leads/repositories/contactsRepository";
import { useDi } from "../../DiContext";

interface MetricState {
  data: ValueMetric | null;
  isLoading: boolean;
  error: Error | null;
}

interface ViewLeadsAnalyticsContextValue {
  metrics: {
    contacts: MetricState;
    conversions: MetricState;
    responseTime: MetricState;
    satisfaction: MetricState;
  };
  getMetrics: (options?: LeadFilterOptions) => Promise<void>;
}

const ViewLeadsAnalyticsContext = createContext<
  ViewLeadsAnalyticsContextValue | undefined
>(undefined);

export const ViewLeadsAnalyticsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { leadsAnalyticsRepository } = useDi();
  const [metrics, setMetrics] = React.useState<
    ViewLeadsAnalyticsContextValue["metrics"]
  >({
    contacts: { data: null, isLoading: true, error: null },
    conversions: { data: null, isLoading: true, error: null },
    responseTime: { data: null, isLoading: true, error: null },
    satisfaction: { data: null, isLoading: true, error: null },
  });

  const getMetrics = useCallback(
    async (options?: LeadFilterOptions) => {
      // Reset loading states
      setMetrics((prev) => ({
        contacts: { ...prev.contacts, isLoading: true, error: null },
        conversions: { ...prev.conversions, isLoading: true, error: null },
        responseTime: { ...prev.responseTime, isLoading: true, error: null },
        satisfaction: { ...prev.satisfaction, isLoading: true, error: null },
      }));

      try {
        const contactMetrics = await leadsAnalyticsRepository.getContactMetrics(
          options
        );
        setMetrics((prev) => ({
          ...prev,
          contacts: {
            data: contactMetrics,
            isLoading: false,
            error: null,
          },
        }));
      } catch (err) {
        const error =
          err instanceof Error
            ? err
            : new Error("Failed to fetch contact metrics");
        setMetrics((prev) => ({
          ...prev,
          contacts: {
            ...prev.contacts,
            isLoading: false,
            error,
          },
        }));
      }

      // TODO: Add other metric fetching logic here when endpoints are available
      // For now, setting them as not loading
      setMetrics((prev) => ({
        ...prev,
        conversions: { ...prev.conversions, isLoading: false },
        responseTime: { ...prev.responseTime, isLoading: false },
        satisfaction: { ...prev.satisfaction, isLoading: false },
      }));
    },
    [leadsAnalyticsRepository]
  );

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  const value = React.useMemo(
    () => ({
      metrics,
      getMetrics,
    }),
    [metrics, getMetrics]
  );

  return (
    <ViewLeadsAnalyticsContext.Provider value={value}>
      {children}
    </ViewLeadsAnalyticsContext.Provider>
  );
};

export const useViewLeadsAnalytics = (): ViewLeadsAnalyticsContextValue => {
  const context = useContext(ViewLeadsAnalyticsContext);
  if (!context) {
    throw new Error(
      "useViewLeadsAnalytics must be used within a ViewLeadsAnalyticsProvider"
    );
  }
  return context;
};
