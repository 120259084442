import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDi } from "../../DiContext";
import { Lead } from "../../domain/src/leads/entities";
import { LeadFilterOptions } from "../../domain/src/leads/repositories/contactsRepository";

type ContextType = {
  leads: Lead[];
  loading: boolean;
  loaded: boolean;
  total: number;
  page: number;
  totalPages: number;
  hasMore: boolean;
  getLeads: (page?: number, options?: LeadFilterOptions) => Promise<void>;
};

export const LeadsContext = createContext<ContextType>({
  leads: [],
  loading: false,
  loaded: false,
  total: 0,
  page: 1,
  totalPages: 0,
  hasMore: false,
  getLeads: async () => {},
});

export const useLeads = () => {
  const context = useContext(LeadsContext);
  if (!context) {
    throw new Error("useLeads must be used within a LeadsProvider");
  }
  return context;
};

type PropsType = {
  children: React.ReactElement;
};

const LeadsProvider = ({ children }: PropsType) => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const { leadsRepository } = useDi();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLeads = useCallback(
    async (page?: number, options?: LeadFilterOptions) => {
      console.log("getLeads");
      setLoading(true);
      try {
        const leadsData = await leadsRepository.getLeads(page, options);
        setLeads(leadsData.leads);
        setTotal(leadsData.total);
        setPage(leadsData.page);
        setTotalPages(leadsData.totalPages);
        setHasMore(leadsData.hasMore);
        setLoaded(true);
      } catch (err) {
        console.error(err);
        alert("Something went wrong. Please refresh");
      } finally {
        setLoading(false);
      }
    },
    [leadsRepository]
  );

  useEffect(() => {
    getLeads();
  }, [getLeads]);

  return (
    <LeadsContext.Provider
      value={{
        leads,
        loading,
        loaded,
        total,
        page,
        totalPages,
        hasMore,
        getLeads,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};

export default LeadsProvider;
