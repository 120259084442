import { FC, useState, useMemo } from "react";
import { Attribute } from "../../../domain/src/leads/entities/attribute";
import { Prompt } from "../../../domain/src/leads/entities/prompt";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { X } from "lucide-react";
import {
  ATTRIBUTE_LABELS,
  LeadAttributeKeys,
} from "../constants/leadAttributeMappings";

interface PromptAttributeFilterChipListProps {
  attributes: Attribute[];
  prompts: Prompt[];
  onFilterChange: (attribute: string, value: string) => void;
}

const PromptAttributeFilterChipList: FC<PromptAttributeFilterChipListProps> = ({
  attributes,
  prompts,
  onFilterChange,
}) => {
  const [selectedAttribute, setSelectedAttribute] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [appliedFilters, setAppliedFilters] = useState<{
    [key: string]: string;
  }>({});

  const selectedPrompt = useMemo(() => {
    if (!selectedAttribute) return null;
    return prompts.find((p) => p.variableName === selectedAttribute);
  }, [selectedAttribute, prompts]);

  const handleApply = (close: () => void) => {
    setAppliedFilters((prev) => ({
      ...prev,
      [selectedAttribute]: filterValue,
    }));
    onFilterChange(selectedAttribute, filterValue);
    close();
  };

  const handleCancel = (close: () => void) => {
    close();
  };

  const handleClearFilter = (attributeName: string) => {
    if (attributeName === selectedAttribute) {
      setSelectedAttribute("");
      setFilterValue("");
    }
    setAppliedFilters((prev) => {
      const newFilters = { ...prev };
      delete newFilters[attributeName];
      return newFilters;
    });
    onFilterChange(attributeName, "");
  };

  const renderFilterMenu = (close: () => void): React.ReactElement => {
    if (!selectedPrompt) return <></>;

    let input;
    switch (selectedPrompt.promptType) {
      case "text":
      case "number":
        input = (
          <input
            type={"text"}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            className="w-full px-3 py-2 bg-gray-700 rounded-md text-white border border-gray-600 focus:border-blue-500 focus:outline-none"
            placeholder={`Enter ${selectedPrompt.text.toLowerCase()}`}
          />
        );
        break;
      case "list":
        input = (
          <select
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            className="w-full px-3 py-2 bg-gray-700 rounded-md text-white border border-gray-600 focus:border-blue-500 focus:outline-none"
          >
            <option value="">Select an option</option>
            {selectedPrompt.choices.map((choice) => (
              <option key={choice.id} value={choice.text}>
                {choice.text}
              </option>
            ))}
          </select>
        );
        break;
    }

    return (
      <div className="bg-gray-800 rounded-lg shadow-lg p-4 space-y-4 border border-gray-600 min-w-[250px]">
        <div className="font-medium text-gray-200">{selectedPrompt.text}</div>
        {input}
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => handleCancel(close)}
            className="px-3 py-1 text-sm text-gray-300 hover:text-white"
          >
            Cancel
          </button>
          <button
            onClick={() => handleApply(close)}
            className="px-3 py-1 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Apply
          </button>
        </div>
      </div>
    );
  };

  const allowedAttributes = [
    LeadAttributeKeys.OFFER_PREFERENCE,
    LeadAttributeKeys.GOAL,
    LeadAttributeKeys.INJURIES,
    LeadAttributeKeys.CURRENT_ACTIVITY,
    LeadAttributeKeys.MEDICAL_CLEARANCE,
    LeadAttributeKeys.PACKAGE,
  ];

  const filteredAttributes = attributes.filter(
    (attribute) =>
      prompts.some((prompt) => prompt.variableName === attribute.name) &&
      allowedAttributes.includes(attribute.name as LeadAttributeKeys)
  );

  return (
    <div className="relative">
      <div className="flex flex-wrap gap-2">
        {filteredAttributes.map((attribute) => {
          const isSelected = attribute.name in appliedFilters;
          return (
            <Popover key={attribute.id}>
              <div className="flex items-center">
                <PopoverButton
                  onClick={() => {
                    setSelectedAttribute(attribute.name);
                    setFilterValue(appliedFilters[attribute.name] || "");
                  }}
                  className={`px-3 py-1 rounded-full text-sm border transition-colors ${
                    isSelected
                      ? "bg-blue-600 border-blue-500 text-white"
                      : "bg-gray-700 border-gray-600 text-gray-300 hover:border-gray-500"
                  }`}
                >
                  <div className="flex items-center">
                    <span>
                      {ATTRIBUTE_LABELS[attribute.name] || attribute.label}
                    </span>
                    {isSelected && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClearFilter(attribute.name);
                        }}
                        className="ml-2 p-1 rounded-full hover:bg-blue-700 transition-colors"
                      >
                        <X size={14} />
                      </button>
                    )}
                  </div>
                </PopoverButton>
              </div>
              <PopoverPanel
                transition
                anchor="bottom"
                className="absolute z-50 rounded-lg bg-gray-800 shadow-lg transition duration-200 ease-in-out [--anchor-gap:0.5rem] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
              >
                {({ close }): React.ReactElement => renderFilterMenu(close)}
              </PopoverPanel>
            </Popover>
          );
        })}
      </div>
    </div>
  );
};

export default PromptAttributeFilterChipList;
