import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

const PageLayoutWithHeader = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();

  return (
    <div className="py-10">
      <header>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <button
            onClick={() => navigate(-1)}
            className="mb-4 flex items-center text-gray-400 hover:text-gray-200"
          >
            <ArrowLeft className="h-5 w-5 mr-1" />
            Back
          </button>
          <h1 className="text-3xl font-bold tracking-tight text-gray-200">
            {title}
          </h1>
          {subtitle && <p className="mt-2 text-lg text-gray-300">{subtitle}</p>}
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
          {children}
        </div>
      </main>
    </div>
  );
};

export default PageLayoutWithHeader;
