export interface DateRange {
  fromDate?: Date;
  toDate?: Date;
}

interface RelativeDateRangeButtonGroupProps {
  onRangeChange: (
    range: DateRange,
    selectedRange: "total" | "month" | "week" | "today"
  ) => void;
  selectedRange: "total" | "month" | "week" | "today";
}

const RelativeDateRangeButtonGroup: React.FC<
  RelativeDateRangeButtonGroupProps
> = ({ onRangeChange, selectedRange }) => {
  const handleRangeChange = (range: "total" | "month" | "week" | "today") => {
    const now = new Date();
    let fromDate: Date | undefined;
    const toDate = now;

    switch (range) {
      case "month":
        fromDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
        break;
      case "week":
        fromDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - now.getDay(),
          0,
          0,
          0,
          0
        );
        break;
      case "today":
        fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        break;
      case "total":
      default:
        fromDate = undefined;
        break;
    }

    onRangeChange({ fromDate, toDate }, range);
  };

  const buttonClasses = (isActive: boolean) => `
    relative inline-flex items-center px-2 py-1.5 text-sm font-medium
    border border-gray-600 focus:z-10 transition-colors
    ${
      isActive
        ? "bg-blue-600 text-white border-blue-500"
        : "bg-gray-700 text-gray-300 hover:bg-gray-600"
    }
  `;

  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        onClick={() => handleRangeChange("total")}
        className={`${buttonClasses(selectedRange === "total")} rounded-l-md`}
      >
        Total
      </button>
      <button
        type="button"
        onClick={() => handleRangeChange("month")}
        className={`${buttonClasses(
          selectedRange === "month"
        )} -ml-px whitespace-nowrap`}
      >
        MTD
      </button>
      <button
        type="button"
        onClick={() => handleRangeChange("week")}
        className={`${buttonClasses(
          selectedRange === "week"
        )} -ml-px whitespace-nowrap`}
      >
        WTD
      </button>
      <button
        type="button"
        onClick={() => handleRangeChange("today")}
        className={`${buttonClasses(
          selectedRange === "today"
        )} -ml-px rounded-r-md`}
      >
        Today
      </button>
    </span>
  );
};

export default RelativeDateRangeButtonGroup;
