import axios from "axios";
import {
  NotificationService,
  NotificationStatusUpdateFields,
} from "../../../domain/src/leads/services/notificationStatusService";
import { NotificationStatusFilter } from "../../../domain/src/leads/entities/notificationStatusFilter";
import { NotificationStatus } from "../../../domain/src/leads/entities/notificationStatus";
import { TokenStorageService } from "../../../domain/src/auth/services/tokenStorageService";
import { IRestApiConfig } from "../../auth/services/RestApiConfig";

export class RESTNotificationStatusService implements NotificationService {
  private readonly baseUrl = "/api/crm/notification-statuses/";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly config: IRestApiConfig
  ) {}

  async getNotificationStatuses(filter: NotificationStatusFilter): Promise<{
    items: NotificationStatus[];
    total: number;
  }> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
        },
      }
    );

    return {
      items: response.data.results.map((notification: any) => ({
        id: notification.id,
        status: notification.status,
        scheduled_for: notification.scheduled_for,
        sent_at: notification.sent_at,
        pending_message: notification.pending_message,
        pending_subject: notification.pending_subject,
      })),
      total: response.data.count,
    };
  }

  async updateNotificationStatus(
    id: string,
    fields: NotificationStatusUpdateFields
  ): Promise<NotificationStatus> {
    const token = this.tokenStorage.getToken();
    const response = await axios.patch(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}${id}/`,
      fields,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return {
      id: response.data.id,
      status: response.data.status,
      scheduled_for: response.data.scheduled_for,
      sent_at: response.data.sent_at,
      pending_message: response.data.pending_message,
      pending_subject: response.data.pending_subject,
    };
  }

  async cancelNotificationStatus(id: number): Promise<NotificationStatus> {
    const token = this.tokenStorage.getToken();
    const response = await axios.post(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}/${id}/cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return {
      id: response.data.id,
      status: response.data.status,
      scheduled_for: response.data.scheduled_for,
      sent_at: response.data.sent_at,
      pending_message: response.data.pending_message,
      pending_subject: response.data.pending_subject,
    };
  }
}
