import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "../../components/common/Loader";
import { AuthContext } from "../../providers/admin/AuthProvider";

interface AuthProtectedProps {
  children: React.ReactNode;
}

const AuthProtected: React.FC<AuthProtectedProps> = ({ children }) => {
  const { authenticatedState, loading, loaded } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    return <Loader />;
  }

  if (!loaded) {
    return <Loader />;
  }

  if (
    !authenticatedState.isAuthenticated &&
    location.pathname.startsWith("/admin")
  ) {
    return <Navigate to={"/"} replace state={{ from: location }} />;
  }
  console.log(children);
  return <>{children}</>;
};

export default AuthProtected;
