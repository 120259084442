import { FC } from "react";
import clsx from "clsx";

type StatusVariant =
  | "gray"
  | "red"
  | "yellow"
  | "green"
  | "blue"
  | "indigo"
  | "purple"
  | "pink";

interface StatusBadgeProps {
  status: string;
  variant?: StatusVariant;
}

const variantStyles: Record<
  StatusVariant,
  { background: string; text: string; ring: string }
> = {
  gray: {
    background: "bg-gray-400/10",
    text: "text-gray-400",
    ring: "ring-gray-400/20",
  },
  red: {
    background: "bg-red-400/10",
    text: "text-red-400",
    ring: "ring-red-400/20",
  },
  yellow: {
    background: "bg-yellow-400/10",
    text: "text-yellow-500",
    ring: "ring-yellow-400/20",
  },
  green: {
    background: "bg-green-500/10",
    text: "text-green-400",
    ring: "ring-green-500/20",
  },
  blue: {
    background: "bg-blue-400/10",
    text: "text-blue-400",
    ring: "ring-blue-400/30",
  },
  indigo: {
    background: "bg-indigo-400/10",
    text: "text-indigo-400",
    ring: "ring-indigo-400/30",
  },
  purple: {
    background: "bg-purple-400/10",
    text: "text-purple-400",
    ring: "ring-purple-400/30",
  },
  pink: {
    background: "bg-pink-400/10",
    text: "text-pink-400",
    ring: "ring-pink-400/20",
  },
};

const StatusBadge: FC<StatusBadgeProps> = ({ status, variant = "gray" }) => {
  const styles = variantStyles[variant];

  return (
    <span
      className={clsx(
        "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
        styles.background,
        styles.text,
        styles.ring
      )}
    >
      {status}
    </span>
  );
};

export default StatusBadge;
