import { Navigate, Route, Routes } from "react-router-dom";
import AdminTenantSelector from "../components/AdminTenantSelector";
import AdminHome from "../components/AdminHome";
import TenantLayout from "../components/TenantLayout";
import TenantsLayout from "../components/TenantsLayout";

const TenantsRoutes = () => {
  return (
    <Routes>
      <Route element={<TenantsLayout />}>
        <Route path="/tenants" element={<AdminTenantSelector />} />
        <Route element={<TenantLayout />}>
          <Route path="/tenants/:slug/*" element={<AdminHome />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default TenantsRoutes;
