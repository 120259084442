import axios from "axios";
import {
  CommunicationLogsService,
  CommunicationLog,
  CommunicationLogFilterOptions,
} from "../../../domain/src/leads/services/communicationLogsService";
import { PaginatedResponse } from "../../../domain/src/leads/entities/paginatedResponse";
import { TokenStorageService } from "../../../domain/src/auth/services/tokenStorageService";
import { IRestApiConfig } from "../../auth/services/RestApiConfig";

export class RESTCommunicationLogsService implements CommunicationLogsService {
  private readonly baseUrl = "/api/crm/communication-logs";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly config: IRestApiConfig
  ) {}

  async getCommunicationLogs(
    page: number,
    limit: number,
    options?: CommunicationLogFilterOptions
  ): Promise<PaginatedResponse<CommunicationLog>> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          limit,
          ...options,
        },
      }
    );

    return {
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results.map((log: any) => ({
        id: log.id,
        notification_rule: log.notification_rule,
        sent_to: log.sent_to,
        sent_at: new Date(log.sent_at),
        message_body: log.message_body,
        message_subject: log.message_subject,
        message_type: log.message_type,
        status: log.status,
      })),
    };
  }

  async getCommunicationLog(id: string): Promise<CommunicationLog> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return {
      id: response.data.id,
      notification_rule: response.data.notification_rule,
      sent_to: response.data.sent_to,
      sent_at: new Date(response.data.sent_at),
      message_body: response.data.message_body,
      message_subject: response.data.message_subject,
      message_type: response.data.message_type,
      status: response.data.status,
    };
  }
}
