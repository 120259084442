import { LeadsAnalyticsRepository } from "../../../domain/src/leads/repositories/leadsAnalyticsRepository";
import { LeadsAnalyticsService } from "../../../domain/src/leads/services/leadsAnalyticsService";
import { ValueMetric } from "../../../domain/src/leads/entities/valueMetric";
import { LeadFilterOptions } from "../../../domain/src/leads/repositories/contactsRepository";

export class LeadsAnalyticsRepositoryImpl implements LeadsAnalyticsRepository {
  constructor(private readonly leadsAnalyticsService: LeadsAnalyticsService) {}

  async getContactMetrics(options?: LeadFilterOptions): Promise<ValueMetric> {
    return this.leadsAnalyticsService.getContactMetrics(options);
  }
}
