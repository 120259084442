import { WorkoutsAnalyticsRepository } from "../../../domain/src/leads/repositories/workoutsAnalyticsRepository";
import {
  WorkoutsAnalyticsService,
  WorkoutMetrics,
} from "../../../domain/src/leads/services/workoutsAnalyticsService";
import { LeadFilterOptions } from "../../../domain/src/leads/repositories/contactsRepository";

export class WorkoutsAnalyticsRepositoryImpl
  implements WorkoutsAnalyticsRepository
{
  constructor(
    private readonly workoutsAnalyticsService: WorkoutsAnalyticsService
  ) {}

  async getWorkoutMetrics(
    options?: LeadFilterOptions
  ): Promise<WorkoutMetrics> {
    return this.workoutsAnalyticsService.getWorkoutMetrics(options);
  }
}
