import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AdminApp from "./AdminApp";
import "./index.css";
import { DiProvider } from "./DiContext";
import AuthProvider from "./providers/admin/AuthProvider";

const rootContainer = document.getElementById("root");

if (rootContainer) {
  const root = createRoot(rootContainer);
  root.render(
    <BrowserRouter>
      <DiProvider>
        <AuthProvider>
          <AdminApp />
        </AuthProvider>
      </DiProvider>
    </BrowserRouter>
  );
} else {
  console.log("Not here");
}
