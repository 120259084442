import { createContext, useEffect, useState } from "react";
import { AdminRepository } from "../../domain/src/auth/repositories/adminRepository";
import { Tenant } from "../../domain/src/auth/entities/tenant";

interface AdminContextType {
  loading: boolean;
  tenants: Tenant[];
  error: string | null;
}

export const AdminContext = createContext<AdminContextType>({
  loading: true,
  tenants: [],
  error: null,
});

interface AdminProviderProps {
  children: React.ReactNode;
  adminRepository: AdminRepository;
}

const AdminProvider: React.FC<AdminProviderProps> = ({
  children,
  adminRepository,
}) => {
  const [loading, setLoading] = useState(true);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [error, setError] = useState<string | null>(null);

  console.log("rendering admin provider");

  useEffect(() => {
    const loadTenants = async () => {
      try {
        const userTenants = (await adminRepository.getUserTenants()).filter(
          (t) => t.slug !== "public"
        );
        setTenants(userTenants);
        setError(null);
      } catch (err) {
        setError("Failed to load tenants");
        console.error("Error loading tenants:", err);
      } finally {
        console.log("setting loading to false");
        setLoading(false);
      }
    };

    loadTenants();
  }, [adminRepository]);

  return (
    <AdminContext.Provider
      value={{
        loading,
        tenants,
        error,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
