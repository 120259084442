import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDi } from "../../DiContext";
import { Workout } from "../../domain/src/leads/entities/workout";
import { useLeadDetails } from "./LeadDetailsProvider";

interface ContextType {
  workouts: Workout[];
  loading: boolean;
  error: string | null;
  refreshWorkouts: () => Promise<void>;
}

export const LeadWorkoutsContext = createContext<ContextType>({
  workouts: [],
  loading: false,
  error: null,
  refreshWorkouts: async () => {},
});

export const useLeadWorkouts = () => {
  const context = useContext(LeadWorkoutsContext);
  if (!context) {
    throw new Error(
      "useLeadWorkouts must be used within a LeadWorkoutsProvider"
    );
  }
  return context;
};

interface Props {
  children: ReactNode;
}

const LeadWorkoutsProvider = ({ children }: Props) => {
  const { workoutsRepository } = useDi();
  const { lead } = useLeadDetails();

  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchWorkouts = useCallback(async () => {
    if (!lead) return;
    setLoading(true);
    setError(null);
    try {
      // Get assigned workouts
      const workoutsResponse = await workoutsRepository.getWorkouts(1, 100, {
        contact_id: lead?.id.toString() ?? "",
      });
      setWorkouts(workoutsResponse.results);
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An error occurred while fetching workouts"
      );
    } finally {
      setLoading(false);
    }
  }, [workoutsRepository, lead]);

  useEffect(() => {
    fetchWorkouts();
  }, [lead, fetchWorkouts]);

  return (
    <LeadWorkoutsContext.Provider
      value={{
        workouts,
        loading,
        error,
        refreshWorkouts: fetchWorkouts,
      }}
    >
      {children}
    </LeadWorkoutsContext.Provider>
  );
};

export default LeadWorkoutsProvider;
