import { FC, ButtonHTMLAttributes } from "react";
import { cn } from "../../../../utils/cn";

type ButtonVariant =
  | "primary"
  | "secondary"
  | "ghost"
  | "destructive"
  | "outline";
type ButtonSize = "sm" | "md" | "lg";

const variants: Record<ButtonVariant, string> = {
  primary:
    "bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-gray-900",
  secondary:
    "bg-gray-600 text-white hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-900",
  ghost:
    "text-gray-200 hover:text-white hover:bg-gray-800 focus:ring-gray-500 focus:ring-offset-gray-900",
  destructive:
    "bg-red-600 text-white hover:bg-red-700 focus:ring-red-500 focus:ring-offset-gray-900",
  outline:
    "border border-gray-600 text-gray-200 hover:bg-gray-800 hover:text-white focus:ring-gray-500 focus:ring-offset-gray-900",
};

const sizes: Record<ButtonSize, string> = {
  sm: "h-8 px-3 text-sm",
  md: "h-10 px-4",
  lg: "h-12 px-6 text-lg",
};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  className,
  variant = "primary",
  size = "md",
  fullWidth = false,
  disabled,
  loading,
  children,
  ...props
}) => {
  return (
    <button
      className={cn(
        // Base styles
        "inline-flex items-center justify-center rounded-md font-medium",
        "transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2",
        "disabled:pointer-events-none disabled:opacity-50",
        // Variant styles
        variants[variant],
        // Size styles
        sizes[size],
        // Full width
        fullWidth && "w-full",
        // Additional classes
        className
      )}
      disabled={disabled || loading}
      {...props}
    >
      {loading && (
        <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
      )}
      {children}
    </button>
  );
};

export default Button;
