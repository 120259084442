import { PaginatedResponse } from "../../../domain/src/leads/entities/paginatedResponse";
import { Workout } from "../../../domain/src/leads/entities/workout";
import { WorkoutsRepository } from "../../../domain/src/leads/repositories/workoutsRespository";
import {
  WorkoutFilterOptions,
  WorkoutsService,
} from "../../../domain/src/leads/services/workoutsService";

export class WorkoutsRepositoryImpl implements WorkoutsRepository {
  constructor(private readonly workoutsService: WorkoutsService) {}

  async getWorkouts(
    page: number,
    limit: number,
    options?: WorkoutFilterOptions
  ): Promise<PaginatedResponse<Workout>> {
    return this.workoutsService.getWorkouts(page, limit, options);
  }

  async getWorkout(id: string): Promise<Workout> {
    return this.workoutsService.getWorkout(id);
  }
}
