import { ValueMetric } from "../../../domain/src/leads/entities/valueMetric";
import { LeadsAnalyticsService } from "../../../domain/src/leads/services/leadsAnalyticsService";
import { TokenStorageService } from "../../../domain/src/auth";
import { IRestApiConfig } from "../../auth/services/RestApiConfig";
import axios from "axios";
import { LeadFilterOptions } from "../../../domain/src/leads/repositories/contactsRepository";
import { MetricResponse } from "../../types/metric_response";

interface ContactMetricsResponse {
  status: string;
  data: MetricResponse;
}

export class RESTLeadsAnalyticsService implements LeadsAnalyticsService {
  private readonly baseUrl = "/api/crm/admin/contacts/metrics/";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly restApiConfig: IRestApiConfig
  ) {}

  async getContactMetrics(options?: LeadFilterOptions): Promise<ValueMetric> {
    const response = await axios.get<ContactMetricsResponse>(
      `${this.restApiConfig.getSecondaryApiUrl()}${this.baseUrl}`,
      {
        headers: {
          Authorization: `Bearer ${this.tokenStorage.getToken()}`,
        },
        params: options,
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to fetch contact metrics");
    }

    return {
      value: response.data.data.value,
      previousValue: response.data.data.previous_value,
      label: response.data.data.label,
      previousLabel: response.data.data.previous_label,
      change: response.data.data.change,
      format: response.data.data.format,
      prefix: response.data.data.prefix,
      suffix: response.data.data.suffix,
    };
  }
}
