import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { LeadFilterOptions } from "../../../domain/src/leads/repositories/contactsRepository";
import { useAttributes } from "../../../providers/admin/AttributesProvider";
import { useLeads } from "../../../providers/admin/LeadsProvider";
import { usePrompts } from "../../../providers/admin/PromptsProvider";
import { useViewLeadsAnalytics } from "../../../providers/admin/ViewLeadsAnalyticsProvider";
import { useWorkoutsAnalytics } from "../../../providers/admin/WorkoutsAnalyticsProvider";
import { useDebounce } from "../hooks/useDebounce";
import { useNonInitialEffect } from "../hooks/useNonInitialEffect";
import { LeadMetricsRow } from "./LeadMetricsRow";
import PromptAttributeFilterChipList from "./PromptAttributeFilterChipList";
import RelativeDateRangeButtonGroup, {
  DateRange,
} from "./RelativeDateRangeButtonGroup";

const ViewLeads = () => {
  const leadsProvider = useLeads();
  const promptsProvider = usePrompts();
  const attributesProvider = useAttributes();
  const viewLeadsAnalyticsProvider = useViewLeadsAnalytics();
  const workoutsAnalyticsProvider = useWorkoutsAnalytics();
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<DateRange>({});
  const [selectedRange, setSelectedRange] = useState<
    "total" | "month" | "week" | "today"
  >("total");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [userTypedSearchTerm, setUserTypedSearchTerm] = useState<string>("");
  const [selectedAttribute, setSelectedAttribute] = useState<
    string | undefined
  >(undefined);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  const debouncedSearchTerm = useDebounce({ value: userTypedSearchTerm });

  const fetchLeads = useCallback(
    (page: number, params: LeadFilterOptions) => {
      leadsProvider.getLeads(page, params);
    },
    // DO NOT INCLUDE leadsProvider IN THE DEPENDENCY ARRAY
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleUserInputChanged = (value: string) => {
    setUserTypedSearchTerm(value);
  };

  useNonInitialEffect(() => {
    setCurrentPage(1);
    // Reset date range and attribute filters when search is applied
    setDateRange({});
    setSelectedRange("total");
    setSelectedAttribute(undefined);
    setSelectedValue(undefined);
    fetchLeads(1, {
      search: userTypedSearchTerm,
    });
  }, [debouncedSearchTerm]);

  const handleDateRangeChange = (
    range: DateRange,
    selectedRange: "total" | "month" | "week" | "today"
  ) => {
    setDateRange(range);
    setSelectedRange(selectedRange);
    setCurrentPage(1);
    // Reset search when date range is applied
    setSearchTerm("");
    fetchLeads(1, {
      created_at_after: range.fromDate,
      attribute_name: selectedAttribute,
      attribute_value: selectedValue,
    });
    viewLeadsAnalyticsProvider.getMetrics({
      created_at_after: range.fromDate,
      attribute_name: selectedAttribute,
      attribute_value: selectedValue,
    });
    workoutsAnalyticsProvider.getMetrics({
      created_at_after: range.fromDate,
      attribute_name: selectedAttribute,
      attribute_value: selectedValue,
    });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchLeads(page, {
      created_at_after: dateRange.fromDate,
      search: searchTerm,
      attribute_name: selectedAttribute,
      attribute_value: selectedValue,
    });
  };

  const handleAttributeFilter = (attribute: string, value: string) => {
    let newValue = undefined;
    let newAttribute = undefined;

    if (value) {
      newValue = value;
      newAttribute = attribute;
    }

    setSelectedAttribute(newAttribute);
    setSelectedValue(newValue);

    setCurrentPage(1);
    fetchLeads(1, {
      created_at_after: dateRange.fromDate,
      search: searchTerm,
      attribute_name: newAttribute,
      attribute_value: newValue,
    });
    viewLeadsAnalyticsProvider.getMetrics({
      created_at_after: dateRange.fromDate,
      attribute_name: newAttribute,
      attribute_value: newValue,
    });
    workoutsAnalyticsProvider.getMetrics({
      created_at_after: dateRange.fromDate,
      attribute_name: newAttribute,
      attribute_value: newValue,
    });
  };

  return (
    <div className="rounded-lg shadow-lg overflow-hidden space-y-4">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
        <input
          type="text"
          placeholder="Search leads..."
          onChange={(e) => {
            handleUserInputChanged(e.target.value);
          }}
          className="w-full md:w-96 px-4 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:border-blue-500"
        />
        <RelativeDateRangeButtonGroup
          selectedRange={selectedRange}
          onRangeChange={handleDateRangeChange}
        />
      </div>
      <div className="flex flex-row">
        <PromptAttributeFilterChipList
          attributes={attributesProvider.attributes}
          prompts={promptsProvider.prompts}
          onFilterChange={handleAttributeFilter}
        />
      </div>

      <LeadMetricsRow />

      <div className="bg-gray-900 overflow-x-auto">
        {leadsProvider.loading ? (
          <div className="flex justify-center items-center py-20">
            <Loader />
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-600">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  First Name
                </th>
                <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Last Name
                </th>
                <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Phone number
                </th>
                <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Acquired on
                </th>
              </tr>
            </thead>
            <tbody className="bg-gray-800 divide-y divide-gray-600">
              {leadsProvider.leads.map((lead) => (
                <tr
                  key={lead.id}
                  onClick={() => navigate(`${lead.id}`)}
                  className="hover:bg-gray-700 hover:shadow-lg cursor-pointer transition-all duration-200"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {lead.firstName ?? "---"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {lead.lastName ?? "---"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {lead.email ?? "---"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {lead.phoneNumber ?? "---"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {new Date(lead.createdAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="bg-gray-900 px-6 py-4 flex justify-between items-center">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <span className="text-gray-300">
          Page {currentPage} of {leadsProvider.totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!leadsProvider.hasMore}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ViewLeads;
