import axios from "axios";
import { AttributesService } from "../../../domain/src/leads/services/attributesService";
import { Attribute } from "../../../domain/src/leads/entities/attribute";
import { TokenStorageService } from "../../../domain/src/auth/services/tokenStorageService";
import { IRestApiConfig } from "../../auth/services/RestApiConfig";

export class RESTAttributesServiceImpl implements AttributesService {
  private readonly baseUrl = "/api/crm/attributes";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly config: IRestApiConfig
  ) {}

  async getAttributes(): Promise<Attribute[]> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.results.map((attribute: any) => attribute);
  }
}
