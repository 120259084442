import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import { FC, useEffect, useState } from "react";
import Button from "./Button";
import { marked } from "marked";
import TurndownService from "turndown";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

const turndown = new TurndownService({
  headingStyle: "atx",
  codeBlockStyle: "fenced",
});

interface MarkdownEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const MarkdownEditor: FC<MarkdownEditorProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkError, setLinkError] = useState("");

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        paragraph: {
          HTMLAttributes: {
            class: "mb-4 last:mb-0",
          },
        },
        heading: {
          HTMLAttributes: {
            class: "text-gray-100 mb-4",
          },
        },
      }),
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: "text-blue-400 hover:text-blue-500",
        },
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none",
      },
    },
    editable: true,
    content: marked(value), // Convert initial markdown to HTML
    onUpdate: ({ editor }) => {
      // Convert HTML back to markdown when content changes
      const html = editor.getHTML();
      const markdown = turndown.turndown(html);
      onChange?.(markdown);
    },
  });

  // Update editor content when content prop changes
  useEffect(() => {
    if (editor && value !== turndown.turndown(editor.getHTML())) {
      editor.commands.setContent(marked(value));
    }
  }, [value, editor]);

  const setLink = () => {
    setLinkUrl("");
    setLinkError("");
    setIsLinkModalOpen(true);
  };

  const validateUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleSetLink = () => {
    if (!linkUrl) {
      setLinkError("Please enter a URL");
      return;
    }

    if (!validateUrl(linkUrl)) {
      setLinkError("Please enter a valid URL (e.g., https://example.com)");
      return;
    }

    editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: linkUrl })
      .run();
    setLinkUrl("");
    setLinkError("");
    setIsLinkModalOpen(false);
  };

  const unsetLink = () => {
    editor?.chain().focus().unsetLink().run();
  };

  if (!editor) {
    return null;
  }

  return (
    <div className="prose prose-invert max-w-none">
      <div>
        <EditorContent className="focus:outline-none" editor={editor} />
      </div>
      <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
        <div className="flex gap-1 rounded-lg bg-gray-400 p-1 shadow-lg">
          <Button
            size="sm"
            variant="ghost"
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive("bold") ? "bg-gray-700" : ""}
          >
            B
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive("italic") ? "bg-gray-700" : ""}
          >
            I
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive("strike") ? "bg-gray-700" : ""}
          >
            S
          </Button>
          {editor.isActive("link") ? (
            <Button
              size="sm"
              variant="ghost"
              onClick={unsetLink}
              className="bg-gray-700"
            >
              🔗❌
            </Button>
          ) : (
            <Button
              size="sm"
              variant="ghost"
              onClick={setLink}
              className={editor.isActive("link") ? "bg-gray-700" : ""}
            >
              🔗
            </Button>
          )}
        </div>
      </BubbleMenu>

      <Transition appear show={isLinkModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsLinkModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-200"
                  >
                    Insert Link
                  </Dialog.Title>
                  <div className="mt-4">
                    <input
                      type="url"
                      value={linkUrl}
                      onChange={(e) => {
                        setLinkUrl(e.target.value);
                        setLinkError("");
                      }}
                      placeholder="Enter URL (e.g., https://example.com)"
                      className={`w-full rounded-lg bg-gray-700 px-4 py-2 text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 ${
                        linkError
                          ? "ring-2 ring-red-500"
                          : "focus:ring-blue-500"
                      }`}
                    />
                    {linkError && (
                      <p className="mt-2 text-sm text-red-500">{linkError}</p>
                    )}
                  </div>

                  <div className="mt-4 flex justify-end gap-2">
                    <Button
                      variant="ghost"
                      onClick={() => {
                        setIsLinkModalOpen(false);
                        setLinkError("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button onClick={handleSetLink}>Insert</Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default MarkdownEditor;
