import axios from "axios";
import {
  WorkoutsService,
  WorkoutFilterOptions,
} from "../../../domain/src/leads/services/workoutsService";
import { Workout } from "../../../domain/src/leads/entities/workout";
import { PaginatedResponse } from "../../../domain/src/leads/entities/paginatedResponse";
import { TokenStorageService } from "../../../domain/src/auth/services/tokenStorageService";
import { IRestApiConfig } from "../../auth/services/RestApiConfig";

export class RESTWorkoutsService implements WorkoutsService {
  private readonly baseUrl = "/api/admin/generated-workouts";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly config: IRestApiConfig
  ) {}

  async getWorkouts(
    page: number,
    limit: number,
    options?: WorkoutFilterOptions
  ): Promise<PaginatedResponse<Workout>> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          limit,
          ...options,
        },
      }
    );

    return {
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results.map((r: any) => ({
        id: r.id,
        contact: r.contact,
        textFormat: r.text_format,
        jsonFormat: r.json_format,
        workoutTitle: r.workout_title,
        createdAt: r.created_at,
      })),
    };
  }

  async getWorkout(id: string): Promise<Workout> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  }
}
