import { AdminRepository } from "../../../domain/src/auth/repositories/adminRepository";
import { AdminService } from "../../../domain/src/auth/services/adminService";
import { Tenant } from "../../../domain/src/auth/entities/tenant";

export class AdminRepositoryImpl implements AdminRepository {
  constructor(private readonly adminService: AdminService) {}

  async getUserTenants(): Promise<Tenant[]> {
    try {
      return await this.adminService.getUserTenants();
    } catch (error) {
      console.error("Error fetching user tenants:", error);
      return [];
    }
  }

  async getUserRoles(): Promise<
    { id: number; name: string; description: string }[]
  > {
    try {
      return await this.adminService.getUserRoles();
    } catch (error) {
      console.error("Error fetching user roles:", error);
      return [];
    }
  }
}
